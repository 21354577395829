import React, { useState, useRef, useEffect } from 'react';
import { set, useForm } from 'react-hook-form';
import { IMaskInput } from "react-imask";
import { useParams, Link } from 'react-router-dom';
import Api from '../../Api'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import { TextareaAutosize } from '@mui/material';
import CurrencyInput from '../../Components/CurrencyInput';
import Select from 'react-select';



function EditarConta() {
    const { id } = useParams(); // Obtém o id do conta a partir da URL
    const { register, watch, handleSubmit, formState: { errors }, reset, setValue } = useForm()
    const [status, setStatus] = useState({ success: false, message: '' })

    const [produtos, setProdutos] = useState([])
    const [fornecedores, setFornecedores] = useState([])
    const [categoriasDespesa, setCategoriasDespesa] = useState([]);
    const [subcategoriasDespesa, setSubcategoriasDespesa] = useState([]);
    const [categoriaSelecionada, setCategoriaSelecionada] = useState(null);
    const [contasRelacionadas, setContasRelacionadas] = useState([]);
    const [carregandoRelacionadas, setCarregandoRelacionadas] = useState(false);
    const [valorAlterado, setValorAlterado] = useState(false);
    const [valorError, setValorError] = useState(false);

    // Adicionar um estado para controlar se o formulário é válido
    const [formInvalido, setFormInvalido] = useState(false);

    useEffect(() => {
        const buscarConta = async () => {
            try {
                const response = await Api.get(`BuscarConta?id=${id}`);
                console.log(response.data)
                const contaData = response.data.data[0];
                
                // Configurar os campos do formulário
                setValue('produto', contaData.produto);
                setValue('fornecedor', contaData.fornecedor);
                setValue('quantidade', contaData.quantidade);
                setValue('descricao', contaData.descricao);
                setValue('valor', `R$ ${contaData.valor.replace('.', ',')}`);
                setValue('anexo', contaData.anexo);
                setValue('data', contaData.data); // Importante: a data precisa ser carregada corretamente
                setValue('tipo', contaData.tipo);
                
                // Informações de parcelamento
                if (contaData.grupo_parcelas) {
                    setValue('grupo_parcelas', contaData.grupo_parcelas);
                    setValue('parcela_numero', contaData.parcela_numero);
                    setValue('total_parcelas', contaData.total_parcelas);
                }
                
                // Configurar categoria de despesa
                if (contaData.tipo === 'despesa' && contaData.categoria_despesa) {
                    setValue('categoria_despesa', contaData.categoria_despesa);
                    setCategoriaSelecionada(contaData.categoria_despesa);
                    
                    if (contaData.subcategoria_despesa) {
                        setValue('subcategoria_despesa', contaData.subcategoria_despesa);
                    }
                }
                
                // Carregar contas relacionadas se fornecidas diretamente na resposta
                if (contaData.outras_parcelas && contaData.outras_parcelas.length > 0) {
                    const todasParcelas = [
                        {
                            id: contaData.id,
                            descricao: contaData.descricao,
                            valor: contaData.valor,
                            data: contaData.data,
                            parcela_numero: contaData.parcela_numero,
                            total_parcelas: contaData.total_parcelas
                        },
                        ...contaData.outras_parcelas
                    ];
                    
                    // Ordenar por número da parcela
                    todasParcelas.sort((a, b) => a.parcela_numero - b.parcela_numero);
                    setContasRelacionadas(todasParcelas);
                }
                // Se não foram fornecidas, buscar separadamente
                else if (contaData.grupo_parcelas) {
                    buscarParcelasRelacionadas(contaData.grupo_parcelas);
                }

            } catch (error) {
                console.log(error);
            }
        };
        
        // Função para buscar todas as parcelas de um grupo
        const buscarParcelasRelacionadas = async (grupoId) => {
            setCarregandoRelacionadas(true);
            try {
                const response = await Api.get(`BuscarParcelasGrupo?grupoId=${grupoId}`);
                if (response.data && response.data.success && response.data.data) {
                    // Ordenar por número da parcela
                    const parcelas = response.data.data;
                    parcelas.sort((a, b) => a.parcela_numero - b.parcela_numero);
                    setContasRelacionadas(parcelas);
                }
            } catch (error) {
                console.error('Erro ao buscar parcelas relacionadas:', error);
            } finally {
                setCarregandoRelacionadas(false);
            }
        };
        
        buscarConta();

        // Buscar contas relacionadas (parcelas da mesma despesa)
        const buscarContasRelacionadas = async () => {
            if (!id) return;
            
            setCarregandoRelacionadas(true);
            try {
                // Supondo que haja um endpoint para buscar contas relacionadas
                const response = await Api.get(`BuscarContasRelacionadas?id=${id}`);
                if (response.data && response.data.success && response.data.data) {
                    setContasRelacionadas(response.data.data);
                }
            } catch (error) {
                console.error('Erro ao buscar contas relacionadas:', error);
            } finally {
                setCarregandoRelacionadas(false);
            }
        };
        
        buscarContasRelacionadas();

        const buscarProdutos = async () => {
            try {
                const response = await Api.get('ListarProdutos');
                if (response.status === 200) {
                    const produtos = response.data.map((produto) => {
                        return {
                            id: produto.id,
                            nome: produto.nome,
                            codigo_produto: produto.preco,
                            valor_venda: produto.quantidade,
                            descricao: produto.estoque,
                            categoria: produto.categoria,
                            tipo: produto.tipo
                        }
                    })
                    setProdutos(produtos)
                }
            } catch (error) {
                console.log(error);
            }
        }
        buscarProdutos();

        const buscarFornecedores = async () => {
            try {
                const response = await Api.get('ListarFornecedores');
                if (response.status === 200) {
                    const fornecedores = response.data.map((fornecedor) => {
                        return {
                            id: fornecedor.id,
                            nome: fornecedor.nome,
                            nome_contato: fornecedor.nome_contato,
                            nome_fantasia: fornecedor.nome_fantasia
                        }
                    })
                    setFornecedores(fornecedores)
                }
            } catch (error) {
                console.log(error);
            }
        }
        buscarFornecedores();

        // Buscar categorias de despesa
        const getCategoriasDespesa = async () => {
            try {
                const response = await Api.get('ListarCategoriasDespesa');
                if (response.data && response.data.categorias) {
                    setCategoriasDespesa(response.data.categorias);
                }
            } catch (error) {
                console.log('Erro ao buscar categorias de despesa:', error);
            }
        }
        getCategoriasDespesa();

    }, [id, setValue]);

    // Atualiza as subcategorias disponíveis quando uma categoria é selecionada
    useEffect(() => {
        if (categoriaSelecionada) {
            const categoria = categoriasDespesa.find(cat => cat.id === parseInt(categoriaSelecionada));
            if (categoria && categoria.subcategorias) {
                setSubcategoriasDespesa(categoria.subcategorias);
            } else {
                setSubcategoriasDespesa([]);
            }
        } else {
            setSubcategoriasDespesa([]);
        }
    }, [categoriaSelecionada, categoriasDespesa]);

    // Atualizar com useEffect para verificar a validade do formulário
    useEffect(() => {
        // O formulário é inválido se o valor estiver com erro
        setFormInvalido(valorError);
    }, [valorError]);

    const recarregarParcelasRelacionadas = async () => {
        if (!watch('grupo_parcelas')) return;
        
        setCarregandoRelacionadas(true);
        try {
            const response = await Api.get(`BuscarParcelasGrupo?grupoId=${watch('grupo_parcelas')}`);
            if (response.data && response.data.success && response.data.data) {
                // Ordenar por número da parcela
                const parcelas = response.data.data;
                parcelas.sort((a, b) => a.parcela_numero - b.parcela_numero);
                setContasRelacionadas(parcelas);
            }
        } catch (error) {
            console.error('Erro ao recarregar parcelas relacionadas:', error);
        } finally {
            setCarregandoRelacionadas(false);
        }
    };

    const [valor, setValor] = useState('');
    const handleValorChange = (valor) => {
        setValor(valor);
        setValue('valor', valor);
        setValorAlterado(true);
        
        // Limpar o erro quando o usuário insere um valor válido
        const valorNumerico = parseFloat(valor.replace('R$ ', '').replace(/\./g, '').replace(',', '.'));
        if (valor && !isNaN(valorNumerico) && valorNumerico >= 1) {
            setValorError(false);
        } else {
            setValorError(true);
        }
    }

    const handleProdutoChange = (produto) => {
        //const produtoFull = produtos.find((p) => p.id === produto.value)
        setValue('produto', produto.value)
    }

    const handleFornecedorChange = (fornecedor) => {
        setValue('fornecedor', fornecedor.value)
    }

    const handleCategoriaChange = (e) => {
        const categoriaId = e.target.value;
        setValue('categoria_despesa', categoriaId);
        setCategoriaSelecionada(categoriaId);
        
        // Resetar subcategoria quando trocar de categoria
        setValue('subcategoria_despesa', '');
    }

    const onSubmit = async (data) => {
        window.scrollTo(0, 0);
        
        try {
            // Validar que o valor foi preenchido e é maior ou igual a 1
            const valorString = data.valor ? data.valor.toString().replace('R$ ', '').replace(/\./g, '').replace(',', '.') : '';
            const valorNumerico = parseFloat(valorString);
            
            if (!valorString || isNaN(valorNumerico) || valorNumerico < 1) {
                setValorError(true);
                setStatus({
                    success: false,
                    message: "O valor é obrigatório e deve ser maior ou igual a 1."
                });
                return;
            }
            
            // Validar que se subcategoria foi informada, categoria também deve ser
            if (data.subcategoria_despesa && !data.categoria_despesa) {
                setStatus({
                    success: false,
                    message: "Uma subcategoria só pode ser selecionada se uma categoria for informada."
                });
                return;
            }

            const formData = new FormData();
            formData.append('id', id);
            formData.append('descricao', data.descricao);
            formData.append('tipo', data.tipo);
            
            // Tratar o valor para garantir formato correto
            const valorProcessado = data.valor.toString()
                .replace("R$ ", "")
                .replace(/\./g, "")
                .replace(",", ".");
            formData.append('valor', valorProcessado);
            
            // Adicionar a data ao formulário
            if (data.data) {
                formData.append('data', data.data);
            }
            
            // Campos opcionais
            if (data.anexo && data.anexo.length > 0) {
                formData.append('arquivo', data.anexo[0]);
            }
            
            // Produto e fornecedor
            formData.append('produto', data.produto ? JSON.stringify(data.produto) : 'null');
            formData.append('fornecedor', data.fornecedor ? JSON.stringify(data.fornecedor) : 'null');
            
            // Quantidade
            if (data.quantidade) {
                formData.append('quantidade', data.quantidade.replace(",", "."));
            } else {
                formData.append('quantidade', 'null');
            }
            
            // Adicionar categoria e subcategoria de despesa
            if (data.tipo === 'despesa') {
                formData.append('categoria_despesa', data.categoria_despesa || '');
                formData.append('subcategoria_despesa', data.subcategoria_despesa || '');
            } else {
                // Se for receita, limpar categorias
                formData.append('categoria_despesa', '');
                formData.append('subcategoria_despesa', '');
            }

            const response = await Api.put('EditarConta', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            setStatus(response.data);

            if (response.data.success) {
                // Atualizar o item atual na lista de parcelas
                if (contasRelacionadas.length > 0) {
                    const parcelaAtualIndex = contasRelacionadas.findIndex(conta => conta.id === parseInt(id));
                    
                    if (parcelaAtualIndex !== -1) {
                        // Criar uma cópia atualizada da parcela atual
                        const parcelaAtualizada = {
                            ...contasRelacionadas[parcelaAtualIndex],
                            descricao: data.descricao,
                            valor: valorProcessado,
                            data: data.data
                        };
                        
                        // Criar uma nova lista com a parcela atualizada
                        const novasContasRelacionadas = [...contasRelacionadas];
                        novasContasRelacionadas[parcelaAtualIndex] = parcelaAtualizada;
                        
                        // Atualizar a lista no estado
                        setContasRelacionadas(novasContasRelacionadas);
                        
                        // Se o valor foi alterado, é recomendável recarregar todas as parcelas
                        // pois pode haver outros cálculos no backend
                        if (valorAlterado) {
                            recarregarParcelasRelacionadas();
                            setValorAlterado(false);
                        }
                    }
                }
                
                // Adicionar um timer para limpar a mensagem de status após alguns segundos (opcional)
                setTimeout(() => {
                    setStatus({ success: true, message: '' });
                }, 5000);
            }

        } catch (error) {
            console.log(error);
            if (error.response && error.response.data && error.response.data.error) { //se o SERVIDOR retornar um erro, mostra a mensagem de erro
                setStatus({
                    success: false,
                    message: error.response.data.error,
                });
            } else { // Mostra uma mensagem de erro genérica ao usuário
                setStatus({
                    success: false,
                    message: "Ocorreu um erro ao atualizar a conta. Tente novamente mais tarde.",
                });
            }
        }
    };

    const resetFields = (e) => {
        setValue('tipo', e);
        setValue('produto', '');
        setValue('fornecedor', '');
        setValue('quantidade', '');
        setValue('descricao', '');
        setValue('valor', '');
        setValue('anexo', '');
        
        // Limpar campos de categoria se mudar para receita
        if (e === 'receita') {
            setValue('categoria_despesa', '');
            setValue('subcategoria_despesa', '');
            setCategoriaSelecionada(null);
        }
    }

    // Função auxiliar para formatar datas corretamente no padrão brasileiro
    const formatarDataBrasil = (dataString) => {
        if (!dataString) return 'Data inválida';
        
        try {
            // Criar a data a partir da string, adicionando 'T12:00:00' para garantir
            // que seja tratada como meio-dia e não haja problemas com fuso horário
            const data = new Date(`${dataString.split('T')[0]}T12:00:00`);
            
            // Verificar se a data é válida
            if (isNaN(data.getTime())) {
                return 'Data inválida';
            }
            
            // Usar toLocaleDateString para formatação consistente no padrão brasileiro
            return data.toLocaleDateString('pt-BR', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric'
            });
        } catch (error) {
            console.error("Erro ao formatar data:", error, dataString);
            return 'Data inválida';
        }
    };

    return (
        <div className='page-full'>
            <form className="bg-white" onSubmit={handleSubmit(onSubmit)}>
                <div className="header-page-full mb-4 cursor-pointer" onClick={() => window.history.back()} >
                    <FontAwesomeIcon icon="fa-solid fa-arrow-left" />
                </div>
                <div className="pt-5 m-5">
                    <div className="row d-flex justify-content-center align-items-top">
                        <div className='col-md-2'>
                            <span className="ant-avatar-circle nex-avatar default circle-icon">
                                <FontAwesomeIcon icon="fa-regular fa-user" />
                            </span>
                        </div>
                        <div className="col-md-8 mb-5">
                            <h1 className="h3 mb-3 fw-bold">Entradas e saídas</h1>

                            <div>
                                <label className="form-label">
                                    Selecione o tipo:
                                    <RadioGroup className='d-flex flex-row'>
                                        <FormControlLabel
                                            // {...register("tipo")}
                                            value="despesa"
                                            control={<Radio />}
                                            label="Despesa"
                                            className='mr-3'
                                            checked={watch("tipo") === "despesa"}
                                            onChange={() => resetFields('despesa')}
                                        />
                                        <FormControlLabel
                                            // {...register("tipo")}
                                            value="receita"
                                            control={<Radio />}
                                            label="Receita"
                                            className='mr-3'
                                            checked={watch("tipo") === "receita"}
                                            onChange={() => resetFields('receita')}
                                        />
                                    </RadioGroup>
                                </label>
                            </div>

                            {status.message && (
                                <div className={`alert ${status.success ? 'alert-success' : 'alert-danger'} alert-dismissible`} role="alert">
                                    <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                    <div className="alert-icon">
                                        <i className="far fa-fw fa-bell"></i>
                                    </div>
                                    <div className="alert-message">
                                        {status.message}
                                    </div>
                                </div>
                            )}

                            {watch('tipo') === 'despesa' &&
                                <>
                                    <div className='row'>
                                        {/* Categoria de Despesa */}
                                        <div className='col-md-6 mb-3'>
                                            <label className="form-label">Categoria de Despesa</label>
                                            <select 
                                                className="form-select" 
                                                {...register("categoria_despesa")}
                                                onChange={handleCategoriaChange}
                                            >
                                                <option value="">Selecione uma categoria</option>
                                                {categoriasDespesa.map(categoria => (
                                                    <option 
                                                        key={categoria.id} 
                                                        value={categoria.id}
                                                        selected={watch('categoria_despesa') == categoria.id}
                                                    >
                                                        {categoria.nome}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>

                                        {/* Subcategoria de Despesa - Mostrar somente se uma categoria for selecionada e tiver subcategorias */}
                                        {subcategoriasDespesa.length > 0 && (
                                            <div className='col-md-6 mb-3'>
                                                <label className="form-label">Subcategoria de Despesa</label>
                                                <select 
                                                    className="form-select" 
                                                    {...register("subcategoria_despesa")}
                                                >
                                                    <option value="">Selecione uma subcategoria</option>
                                                    {subcategoriasDespesa.map(subcategoria => (
                                                        <option 
                                                            key={subcategoria.id} 
                                                            value={subcategoria.id}
                                                            selected={watch('subcategoria_despesa') == subcategoria.id}
                                                        >
                                                            {subcategoria.nome}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        )}
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-4 mb-3'>
                                            <label className="form-label">Selecione o produto</label>
                                            <Select
                                                placeholder="Selecione"
                                                options={produtos.map(produto => ({
                                                    value: produto.id,
                                                    label: produto.nome
                                                }))}
                                                onChange={handleProdutoChange}
                                                value={
                                                    watch("produto") && {
                                                        value: watch("produto"),
                                                        label: `${produtos.find((produto) => produto.id == watch('produto'))?.nome || 'indefinido'}`
                                                    }
                                                }
                                            />

                                        </div>
                                        <div className='col-md-4 mb-3'>
                                            <label className="form-label">Selecione o fornecedor</label>
                                            <Select
                                                placeholder="Selecione"
                                                options={fornecedores.map(fornecedor => ({
                                                    value: fornecedor.id,
                                                    label: fornecedor.nome_fantasia || fornecedor.nome
                                                }))}
                                                onChange={handleFornecedorChange}
                                                value={
                                                    watch("fornecedor") && {
                                                        value: watch("fornecedor"),
                                                        label: `${fornecedores.find((fornecedor) => fornecedor.id == watch('fornecedor'))?.nome_fantasia || fornecedores.find((fornecedor) => fornecedor.id == watch('fornecedor'))?.nome || 'indefinido'}`
                                                    }
                                                }
                                            />
                                        </div>
                                        <div className='col-md-4 mb-3'>
                                            {watch('produto') && watch('produto').tipo == 'material' ?
                                                <>
                                                    <label className="form-label">Metros²</label>
                                                    <input type="text" pattern="[0-9.,]+" className="form-control" {...register("quantidade")} />
                                                </>
                                                :
                                                <>
                                                    <label className="form-label">Quantidade</label>
                                                    <input type="text" className="form-control" {...register("quantidade")} />
                                                </>
                                            }

                                        </div>

                                    </div>
                                    <div className='row'>
                                        <div className='col-md-4 mb-3'>
                                            <label className="form-label">Descrição</label>
                                            <TextareaAutosize className="form-control" {...register("descricao")} />
                                        </div>
                                        <div className='col-md-3 mb-3'>
                                            <label className="form-label">Valor*</label>
                                            <CurrencyInput 
                                                defaultValue={watch('valor')} 
                                                onChange={handleValorChange} 
                                                className={valorError ? "form-control is-invalid" : "form-control"}
                                            />
                                            {valorError && (
                                                <div className="invalid-feedback">
                                                    O valor é obrigatório e deve ser maior ou igual a 1.
                                                </div>
                                            )}
                                        </div>
                                        {/* Adicionando o campo de data */}
                                        <div className='col-md-2 mb-3'>
                                            <label className="form-label">Data</label>
                                            <input 
                                                type="date" 
                                                className="form-control" 
                                                {...register("data")} 
                                                defaultValue={watch('data')}
                                            />
                                        </div>
                                        <div className='col-md-3 mb-3'>
                                            <label className="form-label">Anexo</label>
                                            <input type="file" className="form-control" {...register("anexo")} />
                                        </div>
                                    </div>
                                    {/* Mostrar contas relacionadas (parcelas) se existirem e for despesa */}
                                    {watch('tipo') === 'despesa' && contasRelacionadas.length > 0 && (
                                        <div className='row mt-4'>
                                            <div className='col-md-12'>
                                                <h5 className="mb-3">
                                                    Parcelas Relacionadas 
                                                    {watch('total_parcelas') > 1 && ` (${watch('parcela_numero')}/${watch('total_parcelas')})`}
                                                    {carregandoRelacionadas && (
                                                        <span className="ms-2">
                                                            <div className="spinner-border spinner-border-sm text-primary" role="status">
                                                                <span className="visually-hidden">Atualizando...</span>
                                                            </div>
                                                        </span>
                                                    )}
                                                </h5>
                                                <div className="table-responsive">
                                                    <table className="table table-bordered table-hover">
                                                        <thead>
                                                            <tr>
                                                                <th>Número</th>
                                                                <th>Descrição</th>
                                                                <th>Data</th>
                                                                <th>Valor</th>
                                                                <th>Ações</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {contasRelacionadas.map((conta) => (
                                                                <tr key={conta.id} className={conta.id === parseInt(id) ? 'table-primary' : ''}>
                                                                    <td>{conta.parcela_numero}/{conta.total_parcelas}</td>
                                                                    <td>{conta.descricao}</td>
                                                                    <td>{formatarDataBrasil(conta.data)}</td>
                                                                    <td>
                                                                        {parseFloat(conta.valor).toLocaleString('pt-BR', { 
                                                                            style: 'currency', 
                                                                            currency: 'BRL' 
                                                                        })}
                                                                    </td>
                                                                    <td>
                                                                        <Link 
                                                                            to={`/editar-conta/${conta.id}`}
                                                                            className={`btn btn-sm ${conta.id === parseInt(id) ? 'btn-secondary disabled' : 'btn-primary'}`}
                                                                        >
                                                                            {conta.id === parseInt(id) ? 'Atual' : 'Editar'}
                                                                        </Link>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                        <tfoot>
                                                            <tr className="table-secondary">
                                                                <td colSpan="3" className="text-end fw-bold">Total:</td>
                                                                <td className="fw-bold">
                                                                    {contasRelacionadas.reduce((total, conta) => 
                                                                        total + parseFloat(conta.valor), 0).toLocaleString('pt-BR', { 
                                                                            style: 'currency', 
                                                                            currency: 'BRL' 
                                                                        })
                                                                    }
                                                                </td>
                                                                <td></td>
                                                            </tr>
                                                        </tfoot>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    
                                    {carregandoRelacionadas && (
                                        <div className="d-flex justify-content-center mt-3">
                                            <div className="spinner-border text-primary" role="status">
                                                <span className="visually-hidden">Carregando...</span>
                                            </div>
                                        </div>
                                    )}
                                </>
                            }
                            {watch('tipo') === 'receita' &&
                                <>
                                    <div className='row'>
                                        <div className='col-md-4 mb-3'>
                                            <label className="form-label">Descrição</label>
                                            <TextareaAutosize className="form-control" {...register("descricao")} />
                                        </div>
                                        <div className='col-md-3 mb-3'>
                                            <label className="form-label">Valor*</label>
                                            <CurrencyInput 
                                                defaultValue={watch('valor')} 
                                                onChange={handleValorChange} 
                                                className={valorError ? "form-control is-invalid" : "form-control"}
                                            />
                                            {valorError && (
                                                <div className="invalid-feedback">
                                                    O valor é obrigatório e deve ser maior ou igual a 1.
                                                </div>
                                            )}
                                        </div>
                                        {/* Adicionando o campo de data */}
                                        <div className='col-md-2 mb-3'>
                                            <label className="form-label">Data</label>
                                            <input 
                                                type="date" 
                                                className="form-control" 
                                                {...register("data")} 
                                                defaultValue={watch('data')}
                                            />
                                        </div>
                                        <div className='col-md-5 mb-3'>
                                            <label className="form-label">Anexo</label>
                                            <input type="file" className="form-control" {...register("anexo")} />
                                        </div>
                                    </div>
                                </>
                            }
                            {watch('anexo') && (
                                <img
                                    src={
                                        watch('anexo') instanceof FileList
                                            ? URL.createObjectURL(watch('anexo')[0])
                                            : `data:image/png;base64,${watch('anexo')}`
                                    }
                                    style={{
                                        display: watch('anexo') ? 'block' : 'none',
                                        marginTop: '10px',
                                        marginLeft: 'auto', // Alinhamento à direita
                                        marginRight: '10px', // Espaçamento direito
                                    }}
                                    width='320'
                                    height='auto'
                                    alt="anexo do produto"
                                />
                            )}
                        </div>
                    </div>
                </div>
                <div className="footer-page-full">
                    <div className="footer-client">
                        <button 
                            className="btn btn-light button-large fw-bold"
                            disabled={formInvalido}
                            title={formInvalido ? "Corrija os erros no formulário antes de continuar" : ""}
                        > 
                            ATUALIZAR 
                        </button>
                        <button type="button" className="btn text-white button-large fw-bold" onClick={() => window.history.back()}> CANCELAR </button>
                    </div>
                </div>
            </form>
        </div>
    );
}
export default EditarConta;
