import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './Sidebar.css';

const Sidebar = () => {
    const { nivel } = {
        nivel: localStorage.getItem('nivel')
    };
    const location = useLocation();
    const [isOpen, setIsOpen] = useState(window.innerWidth > 1024);

    // Fecha o sidebar quando clicar em um link no mobile
    const handleLinkClick = () => {
        if (window.innerWidth <= 1024) {
            setIsOpen(false);
        }
    };

    // Fecha o sidebar quando redimensionar a tela
    useEffect(() => {
        const handleResize = () => {
            setIsOpen(window.innerWidth > 1024);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // Organização dos itens do menu por seções
    const menuSections = {
        'Visão Geral': [
            { nome: 'Dashboard', link: '/home', icon: ['fas', 'tachometer-alt'], nivel: ['1', '2'] },
        ],
        'Ordens de Serviço': [
            { nome: 'O.S Automotiva', link: '/listar-os', icon: ['fas', 'clipboard-list'], nivel: ['1', '2'] },
            { nome: 'O.S Arquitetura', link: '/listar-arquitetura', icon: ['fas', 'house'], nivel: ['1', '2'] },
            { nome: 'Orçamentos Arquitetura', link: '/listar-orcamentos', icon: ['fas', 'file-invoice-dollar'], nivel: ['1'] },
        ],
        'Agendamentos': [
            { nome: 'Agendamentos', link: '/listar-agendamentos', icon: ['fas', 'calendar-alt'], nivel: ['1', '2'] },
        ],
        'Cadastros': [
            { nome: 'Clientes', link: '/listar-clientes', icon: ['fas', 'users'], nivel: ['1', '2'] },
            { nome: 'Funcionários', link: '/listar-funcionarios', icon: ['fas', 'user-tie'], nivel: ['1'] },
            { nome: 'Usuários', link: '/listar-usuarios', icon: ['fas', 'user'], nivel: ['1'] },
            { nome: 'Cargos', link: '/listar-cargos', icon: ['fas', 'briefcase'], nivel: ['1'] },
        ],
        'Produtos e Serviços': [
            { nome: 'Produtos', link: '/listar-produtos', icon: ['fas', 'box-open'], nivel: ['1', '2'] },
            { nome: 'Películas', link: '/listar-peliculas', icon: ['fas', 'film'], nivel: ['1', '2'] },
            { nome: 'Serviços', link: '/listar-servicos', icon: ['fas', 'wrench'], nivel: ['1', '2'] },
            { nome: 'Categorias de Produtos', link: '/listar-categorias', icon: ['fas', 'tags'], nivel: ['1'] },
            { nome: 'Fornecedores', link: '/listar-fornecedores', icon: ['fas', 'truck-loading'], nivel: ['1'] },
        ],
        'Veículos': [
            { nome: 'Veículo', link: '/listar-veiculos', icon: ['fas', 'car'], nivel: ['1', '2'] },
        ],
        'Financeiro': [
            { nome: 'Categorias de Despesas', link: '/listar-categorias-despesas', icon: ['fas', 'tags'], nivel: ['1'] },
            { nome: 'Caixa', link: '/listar-contas', icon: ['fas', 'cash-register'], nivel: ['1'] },
            { nome: 'Despesas', link: '/listar-despesas', icon: ['fas', 'money-bill-wave'], nivel: ['1'] },
            { nome: 'Recebimentos', link: '/listar-pagamentos', icon: ['fas', 'hand-holding-usd'], nivel: ['1'] },
            { nome: 'Comissões', link: '/listar-comissoes', icon: ['fas', 'dollar-sign'], nivel: ['1'] },
        ],
        'Relatórios e Controles': [
            { nome: 'Relatórios', link: '/relatorios', icon: ['fas', 'chart-bar'], nivel: ['1'] },
            { nome: 'Perdas', link: '/listar-perdas', icon: ['fas', 'trash-alt'], nivel: ['1'] },
        ],
    };

    return (
        <>
            {/* Botão Toggle Mobile */}
            <button 
                className="mobile-toggle" 
                onClick={() => setIsOpen(!isOpen)}
                aria-label="Toggle menu"
            >
                <FontAwesomeIcon icon={isOpen ? 'times' : 'bars'} />
            </button>

            {/* Overlay */}
            <div 
                className={`sidebar-overlay ${isOpen ? 'show' : ''}`}
                onClick={() => setIsOpen(false)}
            />

            {/* Sidebar */}
            <div className={`sidebar ${isOpen ? 'open' : ''}`}>
                <div className="sidebar-logo">
                    <img src="/logo-black.png" alt="Logo" />
                </div>
                
                <div className="sidebar-menu">
                    {Object.entries(menuSections).map(([section, items]) => (
                        <div key={section} className="menu-section">
                            <div className="menu-section-title">{section}</div>
                            {items.map(item => (
                                item.nivel.includes(nivel) && (
                                    <Link
                                        key={item.nome}
                                        to={item.link}
                                        className={`menu-item ${location.pathname === item.link ? 'active' : ''}`}
                                        onClick={handleLinkClick}
                                    >
                                        <FontAwesomeIcon icon={item.icon} />
                                        <span>{item.nome}</span>
                                    </Link>
                                )
                            ))}
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

export default Sidebar;
