import React, { useState, useRef, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { IMaskInput } from "react-imask";
import axios from 'axios';
import Api from '../../Api';

import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';

function NovoCliente() {
	const { register, watch, handleSubmit, formState: { errors }, reset, setValue } = useForm()
	const [status, setStatus] = useState({ success: false, message: '' })
	const [endereco, setEndereco] = useState({ cep: '', endereco: '', bairro: '', cidade: '', complemento: '' })

	const tipo = watch("tipo");


	const telefone = useRef(null);
	const celular = useRef(null);
	const contato = useRef(null);
	const rg = useRef(null);
	const cep = useRef(null);
	const cnpj = useRef(null);
	const ie = useRef(null);

	useEffect(() => {
		setValue('telefone', '16 ')
		setValue('celular', '16 ')
		setValue('contato', '16 ')

	}, [setValue])



	const handle_busca_cpf = async () => {
		try {
			console.log(cpfRef.current.value, watch('data_nascimento'))

			const data_de_nascimento_obj = new Date(watch('data_nascimento'));


			//adicionar um dia (não sei porque mas vem um dia a menos ao converter a data)
			data_de_nascimento_obj.setDate(data_de_nascimento_obj.getDate() + 1);

			const data_de_nascimento_formato_pt_br = format(data_de_nascimento_obj, 'dd/MM/yyyy');
			console.log(data_de_nascimento_formato_pt_br)

			const cpf = cpfRef.current.value.replace(/\D/g, '');
			const response = await Api.get(`https://ws.hubdodesenvolvedor.com.br/v2/cpf/?cpf=${cpf}&data=${data_de_nascimento_formato_pt_br}&token=${process.env.REACT_APP_TOKEN}`);
			console.log(response.data)

			console.log(response.data.result.nome_da_pf)
			setValue('nome', response.data.result.nome_da_pf)

			const parts = response.data.result.data_nascimento.split('/');
			const reformattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;

			setValue('data_nascimento', reformattedDate)


		} catch (error) {
			console.log(error)
		}
	}

	const onSubmit = async (data) => {
		window.scrollTo(0, 0);
		try {
			if (tipo === "pf") {
				//zerando estados de pj
				data.cnpj = null;
				data.ie = null;
				// data.razao = null;
				data.nome_fantasia = null;

				//campos com mask
				data.cpf = cpfRef.current.value
				data.rg = rg.current.value

			} else {
				data.cpf = null;
				data.rg = null;
				data.data_nascimento = null;
				data.nome = null;

				data.ie = ie.current.value
				data.cnpj = cnpj.current.value
			}

			// CAMPOS COM MASK (geral)
			if (telefone.current.value.length > 6) {
				data.telefone = telefone.current.value
			} else {
				data.telefone = null;
			}
			if (celular.current.value.length > 6) {
				data.celular = celular.current.value
			} else {
				data.celular = null;
			}
			if (contato.current.value.length > 6) {
				data.contato = contato.current.value
			} else {
				data.contato = null;
			}


			data.cep = cep.current.value

			console.log(data);

			const response = await Api.post('NovoCliente', JSON.stringify(data), {
				headers: { 'Content-Type': 'application/json' } //o formato padrão do axios é x-www-form-urlencoded, entao precisa definir que é json pro backend receber certinho
			});
			setStatus(response.data)

			//Resetar o formulario após enviar e der sucesso
			if (response.data.success === true) {
				const aux = watch('tipo')
				reset()

				setValue('tipo', aux)
				setIsValidCpf(null)
				setValue('telefone', '16 ')
				setValue('celular', '16 ')
				setValue('contato', '16 ')
				setValue('cpf', null)

				setEndereco({ cep: '', endereco: '', bairro: '', cidade: '', complemento: '' });
			}


		} catch (error) {
			console.log(error)
			if (error.response && error.response.data && error.response.data.error) { //se o SERVIDOR retornar um erro, mostra a mensagem de erro
				setStatus({
					success: false,
					message: error.response.data.error,
				});
			} else { // Mostra uma mensagem de erro genérica ao usuário
				setStatus({
					success: false,
					message: "Ocorreu um erro ao cadastrar o cliente. Tente novamente mais tarde.",
				});
			}

		}

	};

// Modificar o handleCepChange para preservar os valores dos telefones
const handleCepChange = async (event) => {
    const cepValue = event.target.value.replace(/\D/g, '')
    if (cepValue.length !== 8) {
        return;
    }

    try {
        const response = await axios.get(`https://viacep.com.br/ws/${cepValue}/json/`)
        
        // Guarda os valores atuais dos telefones
        const currentTelefone = watch('telefone');
        const currentCelular = watch('celular');
        const currentContato = watch('contato');

        // Atualiza os valores do endereço
        setValue('cidade', response.data.localidade);
        setValue('bairro', response.data.bairro);
        setValue('endereco', response.data.logradouro);
        
        // Restaura os valores dos telefones
        setValue('telefone', currentTelefone);
        setValue('celular', currentCelular);
        setValue('contato', currentContato);

        setEndereco({
            cep: response.data.cep,
            endereco: response.data.logradouro,
            bairro: response.data.bairro,
            cidade: response.data.localidade
        });
    } catch (error) {
        console.log(error)
    }
};

	const handleTelefoneChange = (event) => {
		setValue('telefone', event.target.value)
	}
	const handleCelularChange = (event) => {
		setValue('celular', event.target.value)
	}
	const handleContatoChange = (event) => {
		setValue('contato', event.target.value)
	}

	const handleCepValueChange = (event) => {
		setValue('cep', event.target.value)
	}

	const handleCNPJChange = async (event) => {
		const cnpjValue = event.target.value.replace(/\D/g, '')
		if (cnpjValue.length !== 14) {
			return;
		}

		try {
			const response = await axios.get(`https://brasilapi.com.br/api/cnpj/v1/${cnpjValue}`)
			console.log(response.data)
			setEndereco({
				cep: response.data.cep,
				endereco: `${response.data.descricao_tipo_de_logradouro} ${response.data.logradouro}`,
				bairro: response.data.bairro,
				cidade: response.data.municipio,
				numero: response.data.numero,
			});

			const formatedEndereco = response.data.descricao_tipo_de_logradouro + ' ' + response.data.logradouro;
			// Atualiza os valores
			setValue('cidade', response.data.municipio);
			setValue('bairro', response.data.bairro);
			setValue('endereco', formatedEndereco);
			setValue('razao', response.data.razao_social);
			setValue('nome_fantasia', response.data.nome_fantasia);
			setValue('ie', response.data.inscricao_estadual);
			setValue('numero', response.data.numero);
			setValue('telefone', response.data.ddd_telefone_1);
			setValue('email', response.data.email);
			setValue('complemento', response.data.complemento);
			setValue('cep', response.data.cep);
		} catch (error) {
			console.log(error)
		}

	};

	const [isValidCpf, setIsValidCpf] = useState(false);
	const [isCpfTouched, setIsCpfTouched] = useState(false);
	const [isCpfEmpty, setIsCpfEmpty] = useState(true);
	const cpfRef = useRef(null);

	const validateCpf = (cpf) => {
		cpf = cpf.replace(/[^\d]/g, ""); // remove non-digits
		if (cpf.length !== 11) return false; // CPF must have 11 digits

		// calculate first digit verifier
		let sum = 0;
		for (let i = 0; i < 9; i++) sum += parseInt(cpf.charAt(i)) * (10 - i);
		let mod = sum % 11;
		let dv1 = mod < 2 ? 0 : 11 - mod;

		// calculate second digit verifier
		sum = 0;
		for (let i = 0; i < 10; i++) sum += parseInt(cpf.charAt(i)) * (11 - i);
		mod = sum % 11;
		let dv2 = mod < 2 ? 0 : 11 - mod;

		// if (cpf.charAt(9) == dv1 && cpf.charAt(10) == dv2) {
		// 	handle_busca_cpf();
		// }
		return cpf.charAt(9) == dv1 && cpf.charAt(10) == dv2;
	};

	const handleChangeCpf = () => {
		setValue('cpf', cpfRef.current.value)
		const cpf = cpfRef.current.value.replace(/[^\d]/g, "");
		const isValid = cpf ? validateCpf(cpf) : false;
		setIsValidCpf(isValid);
		setIsCpfTouched(true);
		setIsCpfEmpty(cpf === "");

	};

	const handleChangeRg = () => {
		setValue('rg', rg.current.value)
	};


	// Anti uso moderno
	const handleTelefoneFocus = (e) => {
		const input = telefone.current;
		if (input) {
			input.setSelectionRange(4, 4);
		}
	};
	const handleCelularFocus = (e) => {
		const input = celular.current;
		if (input) {
			input.setSelectionRange(4, 4);
		}
	};
	const handleContatoFocus = (e) => {
		const input = contato.current;
		if (input) {
			input.setSelectionRange(4, 4);
		}
	};

	const handleNascimentoChange = (event) => {
		setValue('data_nascimento', event.target.value)
		if (event.target.value.length === 10 && cpfRef.current.value.length === 14)
			handle_busca_cpf();
	}

	function handleKeyUp(e) {
		e.target.value = e.target.value.toUpperCase();
	}

	return (
		<div className='page-full'>
			<form className="bg-white" onSubmit={handleSubmit(onSubmit)}>
				<div className="header-page-full mb-4 cursor-pointer" onClick={() => window.history.back()} >
					<FontAwesomeIcon icon="fa-solid fa-arrow-left" />
				</div>
				<div className="pt-5 m-5">
					<div className="row d-flex justify-content-center align-items-top">
						<div className='col-md-2'>
							<span className="ant-avatar-circle nex-avatar default circle-icon">
								<FontAwesomeIcon icon="fa-regular fa-user" />
							</span>
						</div>
						<div className="col-md-8 mb-5">
							<div className="d-flex justify-content-between">
								<div>
									<h1 className="h3 mb-3 font-bold">Cadastro de Clientes</h1>
								</div>
							</div>

							{status.message && (
								<div className={`alert ${status.success ? 'alert-success' : 'alert-danger'} alert-dismissible`} role="alert">
									<button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
									<div className="alert-icon">
										<i className="far fa-fw fa-bell"></i>
									</div>
									<div className="alert-message">
										{status.message}
									</div>
								</div>
							)}

							<div>
								<label className="form-label">
									Tipo de cliente:
									<RadioGroup className='d-flex flex-row'>
										<FormControlLabel
											{...register("tipo")}
											value="pf"
											control={<Radio />}
											label="Pessoa física"
											className='mr-3'
										/>
										<FormControlLabel
											{...register("tipo")}
											value="pj"
											control={<Radio />}
											label="Pessoa jurídica"
											className='mr-3'
										/>
									</RadioGroup>
								</label>
							</div>

							{tipo === "pf" && (
								<div>
									<div className="row">
										<div className="col-md-6 mb-3">
											<label className="form-label">CPF*</label>
											<IMaskInput
												mask="000.000.000-00"
												type="text"
												inputRef={cpfRef}
												// onChange={handleCpfChange}
												onAccept={handleChangeCpf}
												value={watch('cpf') ? watch('cpf') : ''}
												className={`form-control ${!isCpfEmpty && isCpfTouched ? (isValidCpf === null ? "" : isValidCpf ? "is-valid" : "is-invalid") : ""}`}
											/>
											{isCpfTouched && !isValidCpf && !isCpfEmpty ? (
												<div className="invalid-feedback">CPF inválido.</div>
											) : null}

										</div>

										<div className="col-md-6 mb-3">
											<label className="form-label">Data de Nascimento</label>
											<input type="date" className="form-control" onChange={handleNascimentoChange} value={watch('data_nascimento')} />
										</div>
									</div>
									<div className='row'>
										<div className="col-md-6 mb-3">
											<label className="form-label">Nome Completo*</label>
											<input type="text" className="form-control" {...register("nome", { required: true })} value={watch('nome')} />
											{errors.nome && <span className="text-danger">Este campo é obrigatório</span>}
										</div>

										<div className="col-md-3 mb-3">
											<label className="form-label">RG</label>
											<IMaskInput
												mask="00.000.000-W"
												type="text"
												inputRef={rg}
												onAccept={handleChangeRg}
												value={watch('rg') ? watch('rg') : ''}
												definitions={{
													W: {
														validate(char) {
															return /[0-9a-zA-Z]/.test(char);
														},
													},
												}}
												className="form-control"
											/>
										</div>
										<div className="col-md-3 mb-3">
											<label className="form-label">Apelido</label>
											<input type="text" className="form-control text-uppercase" {...register("razao")} onKeyUp={handleKeyUp} />
											{errors.razao && <span className="text-danger">Este campo é obrigatório</span>}
										</div>


									</div>


								</div>

							)}

							{tipo === "pj" && (
								<div>
									<div className='row'>
										<div className="col-md-6 mb-3">
											<label className="form-label">CNPJ*</label>
											<IMaskInput mask="00.000.000/0000-00" type="text" inputRef={cnpj} {...register("cnpj")} className="form-control" onBlur={handleCNPJChange} />
											{errors.cnpj && <span className="text-danger">Este campo é obrigatório</span>}
										</div>

										<div className="col-md-6 mb-3">
											<label className="form-label">Nome fantasia</label>
											<input onKeyUp={handleKeyUp} type="text" className="form-control text-uppercase" {...register("nome_fantasia")} />
											{/* {errors.nome_fantasia && <span className="text-danger">Este campo é obrigatório</span>} */}
										</div>

										<div className="col-md-6 mb-3">
											<label className="form-label">Razão Social</label>
											<input type="text" onKeyUp={handleKeyUp} className="form-control" {...register("razao")} />
											{/* {errors.razao && <span className="text-danger">Este campo é obrigatório</span>} */}
										</div>

										<div className="col-md-6 mb-3">
											<label className="form-label">IE</label>
											<IMaskInput mask="000.000.000.000" type="text" inputRef={ie} {...register("ie")} className="form-control" />
										</div>

									</div>
								</div>
							)}

							<div className="row">
								<div className="col-md-3 mb-3">
									<label className="form-label">Telefone</label>
									<IMaskInput 
										mask="(00) 0000-0000" 
										type="text" 
										inputRef={telefone} 
										onChange={handleTelefoneChange} 
										onFocus={handleTelefoneFocus} 
										className="form-control" 
										defaultValue={watch('telefone')} 
									/>
								</div>
								<div className="col-md-3 mb-3">
									<label className="form-label">Celular</label>
									<IMaskInput 
										mask="(00) 00000-0000" 
										type="text" 
										inputRef={celular} 
										onChange={handleCelularChange} 
										onFocus={handleCelularFocus} 
										className="form-control" 
										defaultValue={watch('celular')} 
									/>
								</div>
								<div className="col-md-3 mb-3">
									<label className="form-label">Telefone Contato</label>
									<IMaskInput 
										mask="(00) 00000-0000" 
										type="text" 
										inputRef={contato} 
										onChange={handleContatoChange} 
										onFocus={handleContatoFocus} 
										className="form-control" 
										defaultValue={watch('contato')} 
									/>
								</div>
								<div className="col-md-3 mb-3">
									<label className="form-label">Nome Contato</label>
									<input type="text text-uppercase" className="form-control" {...register("nome_contato")} />
								</div>
								<div className="col-md-4 mb-3">
									<label className="form-label">Cep</label>
									<IMaskInput mask="00.000-000" type="text" inputRef={cep} {...register("cep")} className="form-control" onBlur={handleCepChange} />
								</div>

								<div className="col-md-4 mb-3">
									<label className="form-label">Cidade</label>
									<input type="text" className="form-control" {...register("cidade")} />
								</div>

								<div className="col-md-4 mb-3">
									<label className="form-label">Endereço</label>
									<input type="text" className="form-control" {...register("endereco")} />
								</div>

							</div>

							<div className="row">
								<div className="col-md-3 mb-3">
									<label className="form-label">Número</label>
									<input type="text" className="form-control" {...register("numero")} value={endereco.numero} />
								</div>
								<div className="col-md-3 mb-3">
									<label className="form-label">Bairro</label>
									<input type="text" className="form-control" {...register("bairro")} />
								</div>
								<div className="col-md-6 mb-3">
									<label className="form-label">Complemento</label>
									<input type="text" className="form-control" {...register("complemento")} />
								</div>
							</div>

							<div className="row">
								<div className="col-md-4 mb-3">
									<label className="form-label">Quadra</label>
									<input type="text" className="form-control" {...register("quadra")} onKeyUp={handleKeyUp} />
								</div>
								<div className="col-md-4 mb-3">
									<label className="form-label">Lote</label>
									<input type="text" className="form-control" {...register("lote")} />
								</div>
								<div className="col-md-2 mb-3">
									<label className="form-label">Sala</label>
									<input type="text" className="form-control" {...register("sala")} />
								</div>
								<div className="col-md-2 mb-3">
									<label className="form-label">Apto.</label>
									<input type="text" className="form-control" {...register("apto")} />
								</div>

							</div>
							<div className="row">
								<div className="col-md-6 mb-3">
									<label className="form-label">E-mail</label>
									<input type="email" className="form-control" {...register("email")} value={watch('email')} />
								</div>
								<div className="col-md-6 mb-3">
									<label className="form-label">E-mail profissional</label>
									<input type="email" className="form-control" {...register("email_profissional")} />
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="footer-page-full">
					<div className="footer-client">
						<button disabled={!isValidCpf && tipo === "pf"} className="btn btn-light button-large fw-bold"> CADASTRAR </button>
						<button type="button" className="btn text-white button-large fw-bold" onClick={() => window.history.back()}> CANCELAR </button>
					</div>
				</div>
			</form>
		</div>
	);
}

export default NovoCliente
