import { BrowserRouter as Router, Routes, Route, Outlet, Navigate, useNavigate, useLocation } from 'react-router-dom'
import Footer from '../Layout/Footer';
import Header from '../Layout/Header';
import PrivateRoutes from '../PrivateRoutes';
import { useEffect, useState } from 'react';

// Icones do Font 
import '../icons';


// LAYOUT
import Sidebar from '../Layout/Sidebar';

// PAGES
import Home from '../Pages/Home';

//clientes
import NovoCliente from '../Pages/Clientes/NovoCliente';
import EditarCliente from '../Pages/Clientes/EditarCliente';
import ListarClientes from '../Pages/Clientes/ListarClientes';

//usuarios
import NovoUsuario from '../Pages/Usuarios/NovoUsuario';
import ListarUsuarios from '../Pages/Usuarios/ListarUsuarios';
import EditarUsuario from '../Pages/Usuarios/EditarUsuario';

//funcionarios
import NovoFuncionario from '../Pages/Funcionarios/NovoFuncionario';
import ListarFuncionarios from '../Pages/Funcionarios/ListarFuncionarios';
import EditarFuncionario from '../Pages/Funcionarios/EditarFuncionario';

import NovoCargo from '../Pages/Cargos/NovoCargo';
import ListarCargos from '../Pages/Cargos/ListarCargos';
import EditarCargo from '../Pages/Cargos/EditarCargo';

//produtos
import NovoProduto from '../Pages/Produtos/NovoProduto';
import ListarProdutos from '../Pages/Produtos/ListarProdutos';
import EditarProduto from '../Pages/Produtos/EditarProduto';

//peliculas
import NovaPelicula from '../Pages/Peliculas/NovaPelicula';
import ListarPeliculas from '../Pages/Peliculas/ListarPeliculas';
import EditarPelicula from '../Pages/Peliculas/EditarPelicula';

//categorias
import NovaCategoria from '../Pages/Categorias/NovaCategoria';
import ListarCategorias from '../Pages/Categorias/ListarCategorias';
import EditarCategoria from '../Pages/Categorias/EditarCategoria';

//fornecedores
import NovoFornecedor from '../Pages/Fornecedores/NovoFornecedor';
import ListarFornecedores from '../Pages/Fornecedores/ListarFornecedores';
import EditarFornecedor from '../Pages/Fornecedores/EditarFornecedor';

//serviços
import NovoServico from '../Pages/Servicos/NovoServico';
import ListarServicos from '../Pages/Servicos/ListarServicos';
import EditarServico from '../Pages/Servicos/EditarServico';

//veiculos
import NovoVeiculo from '../Pages/Veiculos/NovoVeiculo';
import ListarVeiculos from '../Pages/Veiculos/ListarVeiculos';
import EditarVeiculo from '../Pages/Veiculos/EditarVeiculo';

//OS
import NovaOS from '../Pages/OS/NovaOS';
import ListarOS from '../Pages/OS/ListarOS';
import EditarOS from '../Pages/OS/EditarOS/EditarOS';

//financeiro
import NovaConta from '../Pages/Financeiro/NovaConta';
import ListarContas from '../Pages/Financeiro/ListarContas';
import EditarConta from '../Pages/Financeiro/EditarConta';

import ListarCategoriasDespesas from '../Pages/Categoria-despesa/ListarCategoriasDespesa';
import NovaCategoriaDespesas from '../Pages/Categoria-despesa/NovaCategoriaDespesa';
import EditarCategoriaDespesas from '../Pages/Categoria-despesa/EditarCategoriaDespesa';
import EditarSubcategoriaDespesa from '../Pages/Categoria-despesa/EditarSubcategoriaDespesa';

import ListarPagamentos from '../Pages/Pagamentos/ListarPagamentos';

//agendamento
import ListarAgendamentos from '../Pages/Agendamentos/ListarAgendamentos';
import Login from '../Pages/Login/Login';

//arquitetura
import ListarArquitetura from '../Pages/Arquitetura/ListarArquitetura';
import NovaArquitetura from '../Pages/Arquitetura/NovaArquitetura';
import EditarArquitetura from '../Pages/Arquitetura/EditarArquitetura';

// meu perfil
import Perfil from '../Pages/Perfil/Perfil';

// relatórios
import Relatorio from '../Pages/Relatorios/Relatorios';

//comissoes
import ListarComissoes from '../Pages/Comissoes/ListarComissoes';
import EditarComissao from '../Pages/Comissoes/EditarComissao';
import NovaComissao from '../Pages/Comissoes/NovaComissao';

//perdas
import ListarPerdas from '../Pages/Perdas/ListarPerdas';
import NovaPerda from '../Pages/Perdas/NovaPerda';
import EditarPerda from '../Pages/Perdas/EditarPerda';

//orçamentos ????
import NovoOrcamento from '../Pages/Orcamentos/NovoOrcamento';
import ListarOrcamentos from '../Pages/Orcamentos/ListarOrcamentos';
import EditarOrcamento from '../Pages/Orcamentos/EditarOrcamento';

import LoginLayout from '../Pages/Login/LoginLayout';

// Adicione a importação para a nova tela
import ListarDespesas from '../Pages/Despesas/ListarDespesas';

function MainContent() {
    const location = useLocation();
    const [showSidebar, setShowSidebar] = useState(false);

    useEffect(() => {
        setShowSidebar(!showSidebar)
    }, [location]);

    return (
        <Routes>
            <Route path="/login" element={<LoginLayout />} />

            <Route element={<PrivateRoutes />}>
                <Route element={<DefaultLayout />}>
                    <Route path="/" element={<Home />} />
                    <Route path="/home" element={<Home />} />
                    <Route path="/cadastrar-cliente" element={<NovoCliente />} />
                    <Route path="/editar-cliente/:id" element={<EditarCliente />} />
                    <Route path="/listar-clientes" element={<ListarClientes />} />

                    <Route path="/cadastrar-usuario" element={<NovoUsuario />} />
                    <Route path="/listar-usuarios" element={<ListarUsuarios />} />
                    <Route path="/editar-usuario/:id" element={<EditarUsuario />} />

                    <Route path="/cadastrar-funcionario" element={<NovoFuncionario />} />
                    <Route path="/listar-funcionarios" element={<ListarFuncionarios />} />
                    <Route path="/editar-funcionario/:id" element={<EditarFuncionario />} />

                    <Route path="/cadastrar-cargo" element={<NovoCargo />} />
                    <Route path="/listar-cargos" element={<ListarCargos />} />
                    <Route path="/editar-cargo/:id" element={<EditarCargo />} />

                    <Route path="/cadastrar-produto" element={<NovoProduto />} />
                    <Route path="/listar-produtos" element={<ListarProdutos />} />
                    <Route path="/editar-produto/:id" element={<EditarProduto />} />

                    <Route path="/cadastrar-pelicula" element={<NovaPelicula />} />
                    <Route path="/listar-peliculas" element={<ListarPeliculas />} />
                    <Route path="/editar-pelicula/:id" element={<EditarPelicula />} />

                    <Route path="/cadastrar-categoria" element={<NovaCategoria />} />
                    <Route path="/listar-categorias" element={<ListarCategorias />} />
                    <Route path="/editar-categoria/:id" element={<EditarCategoria />} />

                    <Route path="/cadastrar-fornecedor" element={<NovoFornecedor />} />
                    <Route path="/listar-fornecedores" element={<ListarFornecedores />} />
                    <Route path="/editar-fornecedor/:id" element={<EditarFornecedor />} />

                    <Route path="/cadastrar-servico" element={<NovoServico />} />
                    <Route path="/listar-servicos" element={<ListarServicos />} />
                    <Route path="/editar-servico/:id" element={<EditarServico />} />

                    <Route path="/cadastrar-veiculo" element={<NovoVeiculo />} />
                    <Route path="/listar-veiculos" element={<ListarVeiculos />} />
                    <Route path="/editar-veiculo/:id" element={<EditarVeiculo />} />

                    <Route path="/cadastrar-os" element={<NovaOS />} />
                    <Route path="/listar-os" element={<ListarOS />} />
                    <Route path="/editar-os/:id" element={<EditarOS />} />

                    <Route path="/cadastrar-conta" element={<NovaConta />} />
                    <Route path="/listar-contas" element={<ListarContas />} />
                    <Route path="/editar-conta/:id" element={<EditarConta />} />

                    <Route path='/listar-pagamentos' element={<ListarPagamentos />} />

                    <Route path='/listar-agendamentos' element={<ListarAgendamentos />} />

                    <Route path='/listar-arquitetura' element={<ListarArquitetura />} />
                    <Route path='/cadastrar-arquitetura' element={<NovaArquitetura />} />
                    <Route path='/editar-arquitetura/:id' element={<EditarArquitetura />} />

                    <Route path='/perfil' element={<Perfil />} />
                    <Route path='/relatorios' element={<Relatorio />} />

                    <Route path='/listar-comissoes' element={<ListarComissoes />} />
                    <Route path='/editar-comissao/:id' element={<EditarComissao />} />
                    <Route path='/cadastrar-comissao' element={<NovaComissao />} />

                    <Route path='/listar-perdas' element={<ListarPerdas />} />
                    <Route path='/cadastrar-perda' element={<NovaPerda />} />
                    <Route path='/editar-perda/:id' element={<EditarPerda />} />

                    <Route path='/cadastrar-orcamento' element={<NovoOrcamento />} />
                    <Route path='/listar-orcamentos' element={<ListarOrcamentos />} />
                    <Route path='/editar-orcamento/:id' element={<EditarOrcamento />} />


                    <Route path='/listar-categorias-despesas' element={<ListarCategoriasDespesas />} />
                    <Route path='/cadastrar-categoria-despesa' element={<NovaCategoriaDespesas />} />
                    <Route path='/editar-categoria-despesa/:id' element={<EditarCategoriaDespesas />} />
                    <Route path='/editar-subcategoria-despesa/:id' element={<EditarSubcategoriaDespesa />} />
                    
                    <Route path='/listar-despesas' element={<ListarDespesas />} />
                    
                </Route>
            </Route>
        </Routes>
    );
}

const DefaultLayout = () => {
    return (
        <div className="app-container">
            <Sidebar />
            
            <div className="main-content">
                <Header />
                <main>
                    <Outlet />
                </main>
            </div>
        </div>
    );
};

export default MainContent;
