import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useParams, useNavigate } from 'react-router-dom';
import Api from '../../Api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function EditarSubcategoriaDespesa() {
    const { id } = useParams();
    const navigate = useNavigate();
    const { register, handleSubmit, setValue, formState: { errors } } = useForm();
    const [status, setStatus] = useState({ success: false, message: '' });
    const [categorias, setCategorias] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const buscarDados = async () => {
            setLoading(true);
            try {
                // Usar o novo endpoint específico para subcategorias
                const subResponse = await Api.get(`BuscarSubcategoriaDespesa?id=${id}`);
                
                if (subResponse.data && subResponse.data.success && subResponse.data.data) {
                    const subcategoriaData = subResponse.data.data[0]; // Ajustado para acessar o primeiro item do array data
                    setValue('nome', subcategoriaData.nome);
                    setValue('categoriaPai', subcategoriaData.categoriaPai);
                } else {
                    throw new Error("Subcategoria não encontrada");
                }
                
                // Buscar todas as categorias para popular o dropdown
                const catResponse = await Api.get('ListarCategoriasDespesa');
                if (catResponse.data && catResponse.data.categorias) {
                    setCategorias(catResponse.data.categorias);
                }
            } catch (error) {
                console.log(error);
                setStatus({
                    success: false,
                    message: "Erro ao buscar dados da subcategoria. " + (error.message || "")
                });
            } finally {
                setLoading(false);
            }
        };

        buscarDados();
    }, [id, setValue]);

    const onSubmit = async (data) => {
        window.scrollTo(0, 0);
        try {
            data.id = id;
            data.isSubcategoria = true; // Sempre é uma subcategoria neste componente
            
            const response = await Api.put('EditarCategoriaDespesa', JSON.stringify(data), {
                headers: { 'Content-Type': 'application/json' }
            });
            
            setStatus(response.data);

            if (response.data.success) {
                // Redirecionar após 2 segundos
                setTimeout(() => {
                    navigate('/listar-categorias-despesas');
                }, 2000);
            }
        } catch (error) {
            console.log(error);
            if (error.response && error.response.data && error.response.data.error) {
                setStatus({
                    success: false,
                    message: error.response.data.error,
                });
            } else {
                setStatus({
                    success: false,
                    message: "Ocorreu um erro ao editar a subcategoria. Tente novamente mais tarde."
                });
            }
        }
    };

    if (loading) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
                <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Carregando...</span>
                </div>
            </div>
        );
    }

    return (
        <div className='page-full'>
            <form className="bg-white" onSubmit={handleSubmit(onSubmit)}>
                <div className="header-page-full mb-4 cursor-pointer" onClick={() => window.history.back()} >
                    <FontAwesomeIcon icon="fa-solid fa-arrow-left" />
                </div>
                <div className="pt-5 m-5">
                    <div className="row d-flex justify-content-center align-items-top">
                        <div className='col-md-2'>
                            <span className="ant-avatar-circle nex-avatar default circle-icon">
                                <FontAwesomeIcon icon="fa-solid fa-layer-group" />
                            </span>
                        </div>
                        <div className="col-md-8 mb-5">
                            <h1 className="h3 mb-3 fw-bold">Editar Subcategoria de Despesa</h1>

                            {status.message && (
                                <div className={`alert ${status.success ? 'alert-success' : 'alert-danger'} alert-dismissible`} role="alert">
                                    <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                    <div className="alert-icon">
                                        <i className="far fa-fw fa-bell"></i>
                                    </div>
                                    <div className="alert-message">
                                        {status.message}
                                    </div>
                                </div>
                            )}

                            <div className="mb-3">
                                <label className="form-label">Categoria Pai</label>
                                <select className="form-select" {...register("categoriaPai", { required: true })}>
                                    <option value="">Selecione uma categoria</option>
                                    {categorias.map(cat => (
                                        <option key={cat.id} value={cat.id}>{cat.nome}</option>
                                    ))}
                                </select>
                                {errors.categoriaPai && <span className="text-danger">Selecione uma categoria pai</span>}
                            </div>

                            <div className="mb-3">
                                <label className="form-label">Nome da Subcategoria</label>
                                <input type="text" className="form-control" {...register("nome", { required: true })} />
                                {errors.nome && <span className="text-danger">Este campo é obrigatório</span>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-page-full">
                    <div className="footer-client">
                        <button className="btn btn-light button-large fw-bold"> ATUALIZAR </button>
                        <button type="button" className="btn text-white button-large fw-bold" onClick={() => window.history.back()}> CANCELAR </button>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default EditarSubcategoriaDespesa;
