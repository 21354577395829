import React, { useState, useRef, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { IMaskInput } from "react-imask";
import Api from '../../Api';
import Select from 'react-select'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as bootstrap from 'bootstrap';

import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import { TextareaAutosize } from '@mui/material';
import CurrencyInput from '../../Components/CurrencyInput';
import { format, addMonths } from 'date-fns';
import { useLocation } from 'react-router-dom';



function NovaConta() {
    const { register, watch, handleSubmit, formState: { errors }, reset, setValue } = useForm()
    const [status, setStatus] = useState({ success: false, message: '' })
    const [categoriasDespesa, setCategoriasDespesa] = useState([]);
    const [subcategoriasDespesa, setSubcategoriasDespesa] = useState([]);
    const [categoriaSelecionada, setCategoriaSelecionada] = useState(null);
    
    // Declarar valor ANTES de outros estados que dependem dele
    const [valor, setValor] = useState('');
    
    // Adicione variável para controlar erro de valor
    const [valorError, setValorError] = useState(false);
    
    // Estados para parcelamento
    const [parcelas, setParcelas] = useState([]);
    const [mostrarParcelas, setMostrarParcelas] = useState(false);
    const [numParcelas, setNumParcelas] = useState(1);
    const [habilitarParcelamento, setHabilitarParcelamento] = useState(false);
    
    // Alterar para usar uma string temporária no input e só converter para número quando necessário
    const [numParcelasInput, setNumParcelasInput] = useState("1");

    // Adicionar estado para controlar erro de número de parcelas
    const [numParcelasError, setNumParcelasError] = useState(false);

    // Adicionar um estado para controlar se o formulário é válido
    const [formInvalido, setFormInvalido] = useState(false);

    // Obter os parâmetros da URL
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const tipoFromUrl = queryParams.get('tipo');
    
    // Inicializar o tipo com base no parâmetro da URL
    const [tipoSelecionado, setTipoSelecionado] = useState(tipoFromUrl || 'receita');
    
    // Registrar o tipo com o React Hook Form e garantir que o valor esteja correto
    useEffect(() => {
        // Definir o valor do campo "tipo" baseado no parâmetro URL
        if (tipoFromUrl) {
            setValue('tipo', tipoFromUrl);
            setTipoSelecionado(tipoFromUrl);
        }
    }, [tipoFromUrl, setValue]);

    // useState para os campos do formulário
    const [formData, setFormData] = useState({
        // ...outros campos do formulário...
        tipo: tipoFromUrl || 'receita', // Pré-selecionando o tipo com base na URL ou padrão para 'receita'
    });
    
    // useEffect para pré-selecionar o tipo quando o componente montar
    useEffect(() => {
        if (tipoFromUrl) {
            setFormData(prevState => ({
                ...prevState,
                tipo: tipoFromUrl
            }));
        }
    }, [tipoFromUrl]);

    // Efeito para calcular parcelas quando o valor total ou número de parcelas muda
    useEffect(() => {
        if (habilitarParcelamento && valor) {
            // Converter para número garantindo um valor mínimo de 1
            const numParcelasValue = parseInt(numParcelasInput) || 1;
            
            const valorNumerico = parseFloat(valor.replace('R$ ', '').replace('.', '').replace(',', '.'));
            if (!isNaN(valorNumerico)) {
                const valorParcela = valorNumerico / numParcelasValue;
                
                // Criar array com as parcelas
                const novasParcelas = [];
                const hoje = new Date();
                const descricaoPrincipal = watch("descricao") || "";
                const prefixoDescricao = descricaoPrincipal.length > 20 
                    ? descricaoPrincipal.substring(0, 20) + "..." 
                    : descricaoPrincipal;
                
                for (let i = 0; i < numParcelasValue; i++) {
                    const dataParcela = addMonths(hoje, i);
                    novasParcelas.push({
                        numero: i + 1,
                        valor: valorParcela.toFixed(2),
                        data: format(dataParcela, 'yyyy-MM-dd'),
                        descricao: `Parcela ${i + 1}/${numParcelasValue}${prefixoDescricao ? ` - ${prefixoDescricao}` : ""}`
                    });
                }
                
                // Atualizar o estado das parcelas
                setParcelas(novasParcelas);
                
                // Atualizar o estado do númeroParcelas para uso nos cálculos
                setNumParcelas(numParcelasValue);
            }
        } else {
            setParcelas([]);
        }
    }, [habilitarParcelamento, numParcelasInput, valor, watch("descricao")]);

    // Atualizar o useEffect para verificar a validade do formulário sempre que os estados de erro mudarem
    useEffect(() => {
        // O formulário é inválido se:
        // 1. O valor estiver com erro OU
        // 2. Parcelamento estiver habilitado E número de parcelas estiver com erro
        const invalido = valorError || (habilitarParcelamento && numParcelasError);
        setFormInvalido(invalido);
    }, [valorError, numParcelasError, habilitarParcelamento]);

    // Função para atualizar o valor de uma parcela específica
    const atualizarParcela = (index, novoValor) => {
        const parcelasAtualizadas = [...parcelas];
        parcelasAtualizadas[index] = {
            ...parcelasAtualizadas[index],
            valor: novoValor.replace('R$ ', '').replace('.', '').replace(',', '.')
        };
        setParcelas(parcelasAtualizadas);
    };

    // Função para atualizar a data de uma parcela específica
    const atualizarDataParcela = (index, novaData) => {
        const parcelasAtualizadas = [...parcelas];
        
        // Atualiza a data da parcela atual
        parcelasAtualizadas[index] = {
            ...parcelasAtualizadas[index],
            data: novaData
        };
        
        // Se for a primeira parcela, atualiza as datas das parcelas subsequentes
        if (index === 0 && parcelas.length > 1) {
            const dataPrimeiraParcela = new Date(novaData);
            
            for (let i = 1; i < parcelasAtualizadas.length; i++) {
                const novaDataParcela = new Date(dataPrimeiraParcela);
                novaDataParcela.setMonth(dataPrimeiraParcela.getMonth() + i);
                parcelasAtualizadas[i] = {
                    ...parcelasAtualizadas[i],
                    data: format(novaDataParcela, 'yyyy-MM-dd')
                };
            }
        }
        
        setParcelas(parcelasAtualizadas);
    };

    // Função para atualizar a descrição de uma parcela específica
    const atualizarDescricaoParcela = (index, novaDescricao) => {
        const parcelasAtualizadas = [...parcelas];
        parcelasAtualizadas[index] = {
            ...parcelasAtualizadas[index],
            descricao: novaDescricao
        };
        setParcelas(parcelasAtualizadas);
    };

    // Adicionar uma função de formatação de data no padrão brasileiro
    const formatarDataBrasil = (dataString) => {
        try {
            // Usar a data com T12:00:00 para evitar problemas de fuso horário
            const data = new Date(`${dataString.split('T')[0]}T12:00:00`);
            
            // Verificar se a data é válida
            if (isNaN(data.getTime())) {
                return 'Data inválida';
            }
            
            // Formatar para o padrão brasileiro
            return data.toLocaleDateString('pt-BR', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric'
            });
        } catch (error) {
            console.error("Erro ao formatar data:", error, dataString);
            return 'Data inválida';
        }
    };

    // Modificar a função que valida o número de parcelas
    const handleNumParcelasChange = (e) => {
        // Permitir apenas números
        const value = e.target.value.replace(/[^0-9]/g, '');
        setNumParcelasInput(value);
        
        // Validar se é um número inteiro maior ou igual a 1
        const numParcelasValue = parseInt(value);
        if (value && !isNaN(numParcelasValue) && numParcelasValue >= 1) {
            setNumParcelasError(false);
        } else {
            setNumParcelasError(true);
        }
    };

    const onSubmit = async (data) => {
        window.scrollTo(0, 0);
        
        try {
            // Validar que o valor foi preenchido e é maior ou igual a 1
            const valorString = data.valor ? data.valor.replace('R$ ', '').replace(/\./g, '').replace(',', '.') : '';
            const valorNumerico = parseFloat(valorString);
            
            if (!valorString || isNaN(valorNumerico) || valorNumerico < 1) {
                setValorError(true);
                setStatus({
                    success: false,
                    message: "O valor é obrigatório e deve ser maior ou igual a 1."
                });
                return;
            }
            
            // Validar número de parcelas quando o parcelamento está habilitado
            if (habilitarParcelamento) {
                const numParcelasValue = parseInt(numParcelasInput);
                if (!numParcelasInput || isNaN(numParcelasValue) || numParcelasValue < 1) {
                    setNumParcelasError(true);
                    setStatus({
                        success: false,
                        message: "O número de parcelas deve ser um valor inteiro maior ou igual a 1."
                    });
                    return;
                }
            }
            
            // Validar que se subcategoria foi informada, categoria também deve ser
            if (data.subcategoria_despesa && !data.categoria_despesa) {
                setStatus({
                    success: false,
                    message: "Uma subcategoria só pode ser selecionada se uma categoria for informada."
                });
                return;
            }

            // Processamento para parcelamento
            if (habilitarParcelamento && parcelas.length > 0 && data.tipo === 'despesa') {
                const formData = new FormData();
                
                // Campos comuns para todas as parcelas
                formData.append('tipo', 'despesa');
                
                // Adicionar produto se existir
                if (data.produto) {
                    formData.append('produto', JSON.stringify(data.produto));
                }
                
                // Adicionar fornecedor se existir
                if (data.fornecedor) {
                    formData.append('fornecedor', JSON.stringify(data.fornecedor));
                }
                
                // Adicionar quantidade se existir
                if (data.quantidade) {
                    formData.append('quantidade', data.quantidade.replace(",", "."));
                }
                
                // Adicionar anexo apenas para a primeira parcela
                if (data.anexo && data.anexo.length > 0) {
                    formData.append('arquivo', data.anexo[0]);
                }
                
                // Adicionar categorias de despesa
                formData.append('categoria_despesa', data.categoria_despesa || '');
                formData.append('subcategoria_despesa', data.subcategoria_despesa || '');
                
                // Adicionar total de parcelas usando o valor calculado
                formData.append('total_parcelas', numParcelas);
                
                // Preparar array de parcelas no formato que o backend espera
                const parcelasData = parcelas.map(parcela => ({
                    descricao: parcela.descricao,
                    valor: parcela.valor,
                    data: parcela.data,
                    parcela_numero: parcela.numero
                }));
                
                // Converter para JSON e adicionar ao formData
                formData.append('parcelas', JSON.stringify(parcelasData));
                
                try {
                    const response = await Api.post('NovaConta', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    });
                    
                    setStatus(response.data);
                    
                    if (response.data.success) {
                        reset();
                        setHabilitarParcelamento(false);
                        setNumParcelas(1);
                        setParcelas([]);
                        setMostrarParcelas(false);
                        
                        setTimeout(() => {
                            window.history.back();
                        }, 1000);
                    }
                } catch (error) {
                    // ...existing error handling...
                    console.log(error)
                    if (error.response && error.response.data && error.response.data.error) { //se o SERVIDOR retornar um erro, mostra a mensagem de erro
                        setStatus({
                            success: false,
                            message: error.response.data.error,
                        });
                    } else { // Mostra uma mensagem de erro genérica ao usuário
                        setStatus({
                            success: false,
                            message: "Ocorreu um erro ao cadastrar a conta. Tente novamente mais tarde.",
                        });
                    }
                }
                
                return;
            }

            // Processamento normal (sem parcelamento)
            const formData = new FormData();
            
            // Campos padrão
            formData.append('descricao', data.descricao);
            formData.append('tipo', data.tipo);
            formData.append('valor', data.valor.replace(".", "").replace(",", ".").replace("R$ ", ""));
            
            // Campos opcionais que podem ser null ou undefined
            if (data.anexo && data.anexo.length > 0) {
                formData.append('arquivo', data.anexo[0]);
            }
            if (data.produto) {
                formData.append('produto', JSON.stringify(data.produto));
            }
            if (data.fornecedor) {
                formData.append('fornecedor', JSON.stringify(data.fornecedor));
            }
            if (data.quantidade) {
                formData.append('quantidade', data.quantidade.replace(",", "."));
            }

            // Adicionar categoria e subcategoria de despesa, se aplicável
            if (data.tipo === 'despesa') {
                formData.append('categoria_despesa', data.categoria_despesa || '');
                formData.append('subcategoria_despesa', data.subcategoria_despesa || '');
            }

            const response = await Api.post('NovaConta', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            setStatus(response.data);

            if (response.data.success === true) {
                reset();
                setTimeout(() => {
                    window.location.href = '/listar-contas';
                }, 2000);
            }
        } catch (error) {
            // ...existing error handling...
            console.log(error)
            if (error.response && error.response.data && error.response.data.error) { //se o SERVIDOR retornar um erro, mostra a mensagem de erro
                setStatus({
                    success: false,
                    message: error.response.data.error,
                });
            } else { // Mostra uma mensagem de erro genérica ao usuário
                setStatus({
                    success: false,
                    message: "Ocorreu um erro ao cadastrar a conta. Tente novamente mais tarde.",
                });
            }
        }
    };

    const [produtos, setProdutos] = useState([])
    const [fornecedores, setFornecedores] = useState([])

    useEffect(() => {
        //buscar os produtos
        const getProdutos = async () => {
            try {
                const response = await Api.get('ListarProdutos');
                if (response.status === 200) {
                    const produtos = response.data.map((produto) => {
                        return {
                            id: produto.id,
                            nome: produto.nome,
                            codigo_produto: produto.preco,
                            valor_venda: produto.quantidade,
                            descricao: produto.estoque,
                            categoria: produto.categoria,
                            tipo: produto.tipo
                        }
                    })
                    setProdutos(produtos)
                }
            } catch (error) {
                console.log(error)
            }
        }
        getProdutos()

        //buscar os fornecedores
        const getFornecedores = async () => {
            try {
                const response = await Api.get('ListarFornecedores');
                if (response.status === 200) {
                    const fornecedores = response.data.map((fornecedor) => {
                        return {
                            id: fornecedor.id,
                            nome: fornecedor.nome,
                            nome_contato: fornecedor.nome_contato,
                            nome_fantasia: fornecedor.nome_fantasia
                        }
                    })
                    setFornecedores(fornecedores)
                }
            } catch (error) {
                console.log(error)
            }
        }
        getFornecedores()

        // Buscar categorias de despesa
        const getCategoriasDespesa = async () => {
            try {
                const response = await Api.get('ListarCategoriasDespesa');
                if (response.data && response.data.categorias) {
                    setCategoriasDespesa(response.data.categorias);
                }
            } catch (error) {
                console.log('Erro ao buscar categorias de despesa:', error);
            }
        }
        getCategoriasDespesa();
    }, [])

    // Atualiza as subcategorias disponíveis quando uma categoria é selecionada
    useEffect(() => {
        if (categoriaSelecionada) {
            const categoria = categoriasDespesa.find(cat => cat.id === parseInt(categoriaSelecionada));
            if (categoria && categoria.subcategorias) {
                setSubcategoriasDespesa(categoria.subcategorias);
            } else {
                setSubcategoriasDespesa([]);
            }
        } else {
            setSubcategoriasDespesa([]);
        }
    }, [categoriaSelecionada, categoriasDespesa]);

    const handleValorChange = (valor) => {
        setValor(valor);
        setValue('valor', valor);
        
        // Limpar o erro quando o usuário insere um valor válido
        const valorNumerico = parseFloat(valor.replace('R$ ', '').replace(/\./g, '').replace(',', '.'));
        if (valor && !isNaN(valorNumerico) && valorNumerico >= 1) {
            setValorError(false);
        } else {
            setValorError(true);
        }
    }

    const handleProdutoChange = (produto) => {
        const produtoFull = produtos.find((p) => p.id === produto.value)
        setValue('produto', produtoFull)
        setValue('descricao', "Compra de " + produtoFull.nome)
    }

    const handleFornecedorChange = (fornecedor) => {
        const fornecedorFull = fornecedores.find((f) => f.id === fornecedor.value)
        setValue('fornecedor', fornecedorFull)
    }

    const handleCategoriaChange = (e) => {
        const categoriaId = e.target.value;
        setValue('categoria_despesa', categoriaId);
        setCategoriaSelecionada(categoriaId);
        
        // Resetar subcategoria quando trocar de categoria
        setValue('subcategoria_despesa', '');
    }

    // Adicionar inicialização do tooltip no componente
    useEffect(() => {
        // Inicializar todos os tooltips quando o componente montar
        const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
        tooltipTriggerList.map(function (tooltipTriggerEl) {
            return new bootstrap.Tooltip(tooltipTriggerEl)
        });
        
        // Limpar tooltips quando o componente desmontar
        return () => {
            tooltipTriggerList.map(tooltipTriggerEl => {
                const tooltip = bootstrap.Tooltip.getInstance(tooltipTriggerEl);
                if (tooltip) {
                    tooltip.dispose();
                }
                return null;
            });
        };
    }, [parcelas]);

    return (
        <div className='page-full'>
            <form className="bg-white" onSubmit={handleSubmit(onSubmit)}>
                <div className="header-page-full mb-4 cursor-pointer" onClick={() => window.history.back()} >
                    <FontAwesomeIcon icon="fa-solid fa-arrow-left" />
                </div>
                <div className="pt-5 m-5">
                    <div className="row d-flex justify-content-center align-items-top">
                        <div className='col-md-2'>
                            <span className="ant-avatar-circle nex-avatar default circle-icon">
                                <FontAwesomeIcon icon="fa-regular fa-user" />
                            </span>
                        </div>
                        <div className="col-md-8 mb-5">
                            <h1 className="h3 mb-3 fw-bold">Entradas e saídas</h1>

                            <div>
                                <label className="form-label">
                                    Selecione o tipo:
                                    <RadioGroup 
                                        className='d-flex flex-row'
                                        value={tipoSelecionado}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            setTipoSelecionado(value);
                                            setValue('tipo', value);
                                        }}
                                    >
                                        <FormControlLabel
                                            value="despesa"
                                            control={<Radio />}
                                            label="Despesa"
                                            className='mr-3'
                                        />
                                        <FormControlLabel
                                            value="receita"
                                            control={<Radio />}
                                            label="Receita"
                                            className='mr-3'
                                        />
                                    </RadioGroup>
                                </label>
                            </div>

                            {status.message && (
                                <div className={`alert ${status.success ? 'alert-success' : 'alert-danger'} alert-dismissible`} role="alert">
                                    <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                    <div className="alert-icon">
                                        <i className="far fa-fw fa-bell"></i>
                                    </div>
                                    <div className="alert-message">
                                        {status.message}
                                    </div>
                                </div>
                            )}

                            {tipoSelecionado === 'despesa' &&
                                <>
                                    <div className='row'>
                                        {/* Categoria de Despesa */}
                                        <div className='col-md-6 mb-3'>
                                            <label className="form-label">Categoria de Despesa</label>
                                            <select 
                                                className="form-select" 
                                                {...register("categoria_despesa")}
                                                onChange={handleCategoriaChange}
                                            >
                                                <option value="">Selecione uma categoria</option>
                                                {categoriasDespesa.map(categoria => (
                                                    <option key={categoria.id} value={categoria.id}>
                                                        {categoria.nome}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>

                                        {/* Subcategoria de Despesa - Mostrar somente se uma categoria for selecionada e tiver subcategorias */}
                                        {subcategoriasDespesa.length > 0 && (
                                            <div className='col-md-6 mb-3'>
                                                <label className="form-label">Subcategoria de Despesa</label>
                                                <select 
                                                    className="form-select" 
                                                    {...register("subcategoria_despesa")}
                                                >
                                                    <option value="">Selecione uma subcategoria</option>
                                                    {subcategoriasDespesa.map(subcategoria => (
                                                        <option key={subcategoria.id} value={subcategoria.id}>
                                                            {subcategoria.nome}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        )}
                                    </div>

                                    <div className='row'>
                                        <div className='col-md-4 mb-3'>
                                            <label className="form-label">Selecione o produto</label>
                                            <Select
                                                placeholder="Selecione"
                                                options={produtos.map(produto => ({
                                                    value: produto.id,
                                                    label: produto.nome
                                                }))}
                                                onChange={handleProdutoChange}
                                            />
                                        </div>
                                        <div className='col-md-4 mb-3'>
                                            <label className="form-label">Selecione o fornecedor</label>
                                            <Select
                                                placeholder="Selecione"
                                                options={fornecedores.map(fornecedor => ({
                                                    value: fornecedor.id,
                                                    label: fornecedor.nome_fantasia || fornecedor.nome
                                                }))}
                                                onChange={handleFornecedorChange}
                                            />
                                        </div>
                                        <div className='col-md-4 mb-3'>
                                            {watch('produto') && watch('produto').tipo == 'material' ?
                                                <>
                                                    <label className="form-label">Metros²</label>
                                                    <input type="text" pattern="[0-9.,]+" className="form-control" {...register("quantidade")} />
                                                </>
                                                :
                                                <>
                                                    <label className="form-label">Quantidade</label>
                                                    <input type="text" className="form-control" {...register("quantidade")} />
                                                </>
                                            }
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-4 mb-3'>
                                            <label className="form-label">Descrição</label>
                                            <TextareaAutosize className="form-control" {...register("descricao")} defaultValue={watch("descricao")} />
                                        </div>
                                        <div className='col-md-3 mb-3'>
                                            <label className="form-label">Valor*</label>
                                            <CurrencyInput 
                                                defaultValue={valor} 
                                                onChange={handleValorChange} 
                                                className={valorError ? "form-control is-invalid" : "form-control"}
                                            />
                                            {valorError && (
                                                <div className="invalid-feedback">
                                                    O valor é obrigatório e deve ser maior ou igual a 1.
                                                </div>
                                            )}
                                        </div>
                                        <div className='col-md-5 mb-3'>
                                            <label className="form-label">Anexo</label>
                                            <input type="file" className="form-control" {...register("anexo")} />
                                        </div>
                                    </div>
                                    
                                    {/* Opção de parcelamento para despesas */}
                                    <div className='row'>
                                        <div className='col-md-12 mb-3'>
                                            <div className="form-check">
                                                <input 
                                                    type="checkbox" 
                                                    id="habilitarParcelamento" 
                                                    className="form-check-input" 
                                                    checked={habilitarParcelamento}
                                                    onChange={(e) => {
                                                        setHabilitarParcelamento(e.target.checked);
                                                        setMostrarParcelas(e.target.checked);
                                                    }}
                                                />
                                                <label className="form-check-label" htmlFor="habilitarParcelamento">
                                                    Dividir em parcelas
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    {habilitarParcelamento && (
                                        <div className='row'>
                                            <div className='col-md-3 mb-3'>
                                                <label className="form-label">Número de Parcelas*</label>
                                                <input 
                                                    type="text" 
                                                    className={numParcelasError ? "form-control is-invalid" : "form-control"}
                                                    value={numParcelasInput}
                                                    onChange={handleNumParcelasChange}
                                                    onBlur={() => {
                                                        // Ao perder o foco, garantir um valor mínimo de 1 se estiver vazio
                                                        if (!numParcelasInput || parseInt(numParcelasInput) < 1) {
                                                            setNumParcelasInput("1");
                                                            setNumParcelasError(false);
                                                        } else if (parseInt(numParcelasInput) > 36) {
                                                            // Limitar a 36 parcelas
                                                            setNumParcelasInput("36");
                                                            setNumParcelasError(false);
                                                        }
                                                    }}
                                                />
                                                {numParcelasError && (
                                                    <div className="invalid-feedback">
                                                        O número de parcelas deve ser um valor inteiro maior ou igual a 1.
                                                    </div>
                                                )}
                                                <small className="text-muted">
                                                    Mínimo: 1
                                                </small>
                                            </div>
                                            <div className='col-md-3 mb-3'>
                                                <label className="form-label">&nbsp;</label> {/* Label vazia para alinhar com o campo acima */}
                                                <div className="d-grid"> {/* Usando d-grid para ocupar todo o espaço da célula */}
                                                    <button 
                                                        type="button" 
                                                        className="btn btn-secondary"
                                                        onClick={() => setMostrarParcelas(!mostrarParcelas)}
                                                        disabled={numParcelasError}
                                                    >
                                                        {mostrarParcelas ? 'Ocultar Parcelas' : 'Mostrar Parcelas'}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    
                                    {habilitarParcelamento && mostrarParcelas && (
                                        <div className='row'>
                                            <div className='col-md-12'>
                                                <h5 className="mt-3 mb-3">
                                                    Detalhes das Parcelas
                                                    <span 
                                                        className="ms-2 text-primary" 
                                                        style={{ cursor: 'pointer' }}
                                                        data-bs-toggle="tooltip" 
                                                        data-bs-placement="top" 
                                                        title="Estas parcelas serão lançadas como registros separados na listagem de contas."
                                                    >
                                                        <FontAwesomeIcon icon="fa-solid fa-circle-info" />
                                                    </span>
                                                </h5>
                                                <div className="table-responsive">
                                                    <table className="table table-bordered">
                                                        <thead>
                                                            <tr>
                                                                <th>Nº</th>
                                                                <th>Descrição</th>
                                                                <th>Data</th>
                                                                <th>Valor</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {parcelas.map((parcela, index) => (
                                                                <tr key={index}>
                                                                    <td>{parcela.numero}</td>
                                                                    <td>
                                                                        <input 
                                                                            type="text" 
                                                                            className="form-control form-control-sm" 
                                                                            value={parcela.descricao}
                                                                            onChange={(e) => atualizarDescricaoParcela(index, e.target.value)}
                                                                        />
                                                                    </td>
                                                                    <td>
                                                                        <div className="input-group">
                                                                            <input 
                                                                                type="date" 
                                                                                className="form-control form-control-sm" 
                                                                                value={parcela.data}
                                                                                onChange={(e) => atualizarDataParcela(index, e.target.value)}
                                                                                title={formatarDataBrasil(parcela.data)}
                                                                            />
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <CurrencyInput 
                                                                            defaultValue={`R$ ${parseFloat(parcela.valor).toFixed(2).replace('.', ',')}`}
                                                                            onChange={(valor) => atualizarParcela(index, valor)}
                                                                            className="form-control form-control-sm"
                                                                        />
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                        <tfoot>
                                                            <tr className="table-secondary">
                                                                <td colSpan="3" className="text-end fw-bold">Total:</td>
                                                                <td className="fw-bold">
                                                                    {parcelas.reduce((total, parcela) => 
                                                                        total + parseFloat(parcela.valor), 0).toLocaleString('pt-BR', { 
                                                                            style: 'currency', 
                                                                            currency: 'BRL' 
                                                                        })
                                                                    }
                                                                </td>
                                                            </tr>
                                                        </tfoot>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </>
                            }
                            {tipoSelecionado === 'receita' &&
                                <>
                                    <div className='row'>
                                        <div className='col-md-4 mb-3'>
                                            <label className="form-label">Descrição</label>
                                            <TextareaAutosize className="form-control" {...register("descricao")} />
                                        </div>
                                        <div className='col-md-3 mb-3'>
                                            <label className="form-label">Valor*</label>
                                            <CurrencyInput 
                                                defaultValue={valor} 
                                                onChange={handleValorChange} 
                                                className={valorError ? "form-control is-invalid" : "form-control"}
                                            />
                                            {valorError && (
                                                <div className="invalid-feedback">
                                                    O valor é obrigatório e deve ser maior ou igual a 1.
                                                </div>
                                            )}
                                        </div>
                                        <div className='col-md-5 mb-3'>
                                            <label className="form-label">Anexo</label>
                                            <input type="file" className="form-control" {...register("anexo")} />
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
                <div className="footer-page-full">
                    <div className="footer-client">
                        <button 
                            className="btn btn-light button-large fw-bold"
                            disabled={formInvalido}
                            title={formInvalido ? "Corrija os erros no formulário antes de continuar" : ""}
                        > 
                            CADASTRAR 
                        </button>
                        <button type="button" className="btn text-white button-large fw-bold" onClick={() => window.history.back()}> CANCELAR </button>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default NovaConta
