import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import Api from '../../Api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function NovaCategoriaDespesas() {
	const { register, handleSubmit, formState: { errors }, reset } = useForm();
	const [status, setStatus] = useState({ success: false, message: '' });
	const [categorias, setCategorias] = useState([]);
	const [isSubcategoria, setIsSubcategoria] = useState(false);

	useEffect(() => {
		// Carregar categorias para o dropdown de categorias pai
		async function fetchCategorias() {
			try {
				const response = await Api.get('ListarCategoriasDespesa');
				if (response.data && response.data.categorias) {
					setCategorias(response.data.categorias);
				}
			} catch (error) {
				console.error('Erro ao carregar categorias:', error);
			}
		}

		fetchCategorias();
	}, []);

	const onSubmit = async (data) => {
		window.scrollTo(0, 0);
		try {
			// Adicionar flag para identificar se é subcategoria
			data.isSubcategoria = isSubcategoria;

			// Enviar para a API
			const response = await Api.post('NovaCategoriaDespesa', JSON.stringify(data), {
				headers: { 'Content-Type': 'application/json' }
			});

			setStatus(response.data);

			// Resetar o formulário após sucesso
			if (response.data.success === true) {
				reset();
				// Redirecionar após 2 segundos
				setTimeout(() => {
					window.location.href = '/listar-categorias-despesas';
				}, 2000);
			}

		} catch (error) {
			console.log(error);
			if (error.response && error.response.data && error.response.data.error) {
				setStatus({
					success: false,
					message: error.response.data.error,
				});
			} else {
				setStatus({
					success: false,
					message: "Ocorreu um erro ao cadastrar a categoria. Tente novamente mais tarde."
				});
			}
		}
	};

	return (
		<div className='page-full'>
			<form className="bg-white" onSubmit={handleSubmit(onSubmit)}>
				<div className="header-page-full mb-4 cursor-pointer" onClick={() => window.history.back()} >
					<FontAwesomeIcon icon="fa-solid fa-arrow-left" />
				</div>
				<div className="pt-5 m-5">
					<div className="row d-flex justify-content-center align-items-top">
						<div className='col-md-2'>
							<span className="ant-avatar-circle nex-avatar default circle-icon">
								<FontAwesomeIcon icon="fa-solid fa-table-cells-large" />
							</span>
						</div>
						<div className="col-md-8 mb-5">
							<h1 className="h3 mb-3 fw-bold">Informações da Categoria de Despesa</h1>

							{status.message && (
								<div className={`alert ${status.success ? 'alert-success' : 'alert-danger'} alert-dismissible`} role="alert">
									<button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
									<div className="alert-icon">
										<i className="far fa-fw fa-bell"></i>
									</div>
									<div className="alert-message">
										{status.message}
									</div>
								</div>
							)}

							<div className="mb-3 form-check">
								<input 
									type="checkbox" 
									className="form-check-input" 
									id="isSubcategoria" 
									checked={isSubcategoria}
									onChange={(e) => setIsSubcategoria(e.target.checked)}
								/>
								<label className="form-check-label" htmlFor="isSubcategoria">É uma subcategoria</label>
							</div>

							{isSubcategoria && (
								<div className="mb-3">
									<label className="form-label">Categoria Pai</label>
									<select className="form-select" {...register("categoriaPai", { required: isSubcategoria })}>
										<option value="">Selecione uma categoria</option>
										{categorias.map(cat => (
											<option key={cat.id} value={cat.id}>{cat.nome}</option>
										))}
									</select>
									{errors.categoriaPai && <span className="text-danger">Selecione uma categoria pai</span>}
								</div>
							)}

							<div className="mb-3">
								<label className="form-label">{isSubcategoria ? 'Subcategoria' : 'Categoria'}</label>
								<input type="text" className="form-control" {...register("nome", { required: true })} />
								{errors.nome && <span className="text-danger">Este campo é obrigatório</span>}
							</div>
						</div>
					</div>
				</div>
				<div className="footer-page-full">
					<div className="footer-client">
						<button className="btn btn-light button-large fw-bold"> CADASTRAR </button>
						<button type="button" className="btn text-white button-large fw-bold" onClick={() => window.history.back()}> CANCELAR </button>
					</div>
				</div>
			</form>
		</div>
	);
}

export default NovaCategoriaDespesas;
