import React, { useEffect, useState } from 'react'
import Table from '../../Components/Table'
import Api from '../../Api'
import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { parse, isValid, format, parseISO, addMonths, subDays, getMonth, getYear } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import CurrencyInput from '../../Components/CurrencyInput';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

function ListarContas() {

    const [showModalConfirm, setShowModalConfirm] = useState(false);
    const [modalData, setModalData] = useState({});
    const [status, setStatus] = useState({ success: false, message: '' })

    const [showFilterModal, setShowFilterModal] = useState(false);
    const [filterData, setFilterData] = useState({});

    const [totalShow, setTotalShow] = useState({});

    const [excluirTodasParcelas, setExcluirTodasParcelas] = useState(false);

    const [showRelatorioModal, setShowRelatorioModal] = useState(false);
    const [relatorioData, setRelatorioData] = useState({
        periodoInicio: '',
        periodoFim: '',
        modo: 'todos' // Novo campo para controlar o modo de exibição do relatório
    });
    const [relatorioGerado, setRelatorioGerado] = useState(null);
    const [loadingRelatorio, setLoadingRelatorio] = useState(false);

    const deleteRecord = async (data, excluirTodasParcelas = false) => {
        try {
            // Limpa quaisquer mensagens de status anteriores
            setStatus({ success: false, message: '' });
            
            // Construindo a URL com base no modo de exclusão
            let endpoint = `DeletarConta/${data}`;
            
            // Se for para excluir todas as parcelas e o registro tiver um grupoId
            if (excluirTodasParcelas && modalData.grupoId) {
                endpoint = `DeletarConta/${data}?excluirTodasParcelas=true&grupoId=${modalData.grupoId}`;
            } else if (excluirTodasParcelas) {
                endpoint += `?excluirTodasParcelas=true`;
            }
            
            const response = await Api.delete(endpoint);
            setShowModalConfirm(false);
            
            // Limpa o checkbox após a conclusão
            setExcluirTodasParcelas(false);
            
            if (response.data.success === true) {
                setStatus({
                    success: true,
                    message: excluirTodasParcelas ? 
                        "Todas as parcelas foram excluídas com sucesso!" : 
                        "Registro excluído com sucesso!"
                });
            } else {
                // Mostra a mensagem específica retornada pela API
                setStatus({
                    success: false,
                    message: response.data.message || "Não foi possível excluir o registro."
                });
            }
        } catch (error) {
            console.log(error);
            setShowModalConfirm(false);
            setExcluirTodasParcelas(false);
            
            // Verifica se há uma mensagem de erro específica da API
            const errorMessage = error.response?.data?.message || 
                "Ocorreu um erro ao apagar o registro. Tente novamente mais tarde.";
            
            setStatus({
                success: false,
                message: errorMessage
            });
        }
    };

    function parseCustomDate(dateString) {
        // Suportar ambos os formatos, mas garantir exibição no formato brasileiro
        const formatsToTry = ['yyyy-MM-dd', 'dd-MM-yyyy'];

        for (const formatString of formatsToTry) {
            const parsedDate = parse(dateString, formatString, new Date());

            if (isValid(parsedDate)) {
                // Retornar data formatada no padrão brasileiro
                return format(parsedDate, 'dd/MM/yyyy');
            }
        }

        // Tentar um último esforço para formatar a data usando toLocaleDateString
        try {
            // Adicionar o T12:00:00 para prevenir problemas com fuso horário
            const dataPadrao = new Date(`${dateString.split('T')[0]}T12:00:00`);
            if (isValid(dataPadrao)) {
                return dataPadrao.toLocaleDateString('pt-BR');
            }
        } catch (error) {
            console.error("Erro ao formatar data:", error);
        }

        return '-';
    }



    const [contas, setContas] = useState([]);
    const [contasShow, setContasShow] = useState([]);
    const [categoriasDespesa, setCategoriasDespesa] = useState([]);

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await Api.get('ListarContas');
                setContas(response.data.data);
                setContasShow(response.data.data);

                console.log(response.data.data)
            } catch (error) {
                console.error(error);
            }
        }

        // Buscar categorias de despesa
        const getCategoriasDespesa = async () => {
            try {
                const response = await Api.get('ListarCategoriasDespesa');
                if (response.data && response.data.categorias) {
                    setCategoriasDespesa(response.data.categorias);
                }
            } catch (error) {
                console.log('Erro ao buscar categorias de despesa:', error);
            }
        }

        fetchData();
        getCategoriasDespesa();
    }, [status]);

    useEffect(() => { //useeffect pra mostrar só o mes atual quando carregar a página
        const today = new Date();
        const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);

        // Calculate the last day of the current month
        const nextMonth = addMonths(firstDayOfMonth, 1);
        const lastDayOfMonth = subDays(nextMonth, 1);

        setFilterData({
            inicio: format(firstDayOfMonth, 'yyyy-MM-dd'),
            fim: format(lastDayOfMonth, 'yyyy-MM-dd'),
        });
        filtrarLista();
    }, [contas]);

    useEffect(() => { //useeffect pro total no fim da página
        let totalReceitas = 0;
        let totalDespesas = 0;
        let total = 0;
        contasShow.forEach(conta => {
            if (conta.tipo === 'receita') {
                totalReceitas += Number(conta.valor);
            } else {
                totalDespesas += Number(conta.valor);
            }
        });
        total = totalReceitas - totalDespesas;
        setTotalShow({
            receitas: totalReceitas.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', currencyDisplay: 'narrowSymbol' }),
            despesas: totalDespesas.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', currencyDisplay: 'narrowSymbol' }),
            total: total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', currencyDisplay: 'narrowSymbol' }),
        });
    }, [contasShow, filterData]);

    const columns = React.useMemo(
        () => [
            {
                Header: '',
                isVisible: false,
                hideHeader: false,
                id: 'id',
                columns: [
                    {
                        Header: "#",
                        accessor: row => <Link to={`/editar-conta/${row.id}`}>{row.id}</Link> ?? '-'
                    },

                    {
                        Header: "Descrição",
                        accessor: row => row.descricao || '-',
                        Cell: ({ cell: { value }, row: { original } }) => (
                            <Link to={`/editar-conta/${original.id}`}>
                                <div>
                                    {value || '-'}
                                    {original.total_parcelas > 1 && (
                                        <span className="badge bg-info ms-2">
                                            Parcela {original.parcela_numero}/{original.total_parcelas}
                                        </span>
                                    )}
                                </div>
                            </Link>
                        ),
                    },
                    {
                        Header: "Valor",
                        accessor: row => `${Number(row.valor).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', currencyDisplay: 'narrowSymbol' }) ?? '-'}`,
                        Cell: ({ cell: { value }, row: { original } }) => (
                            <Link to={`/editar-conta/${original.id}`} style={{ color: original.tipo === 'despesa' ? 'red' : 'green' }}>
                                {value || '-'}
                            </Link>
                        ),
                    },
                    {
                        Header: "Data",
                        accessor: row => {
                            // Simplificar esta parte, já que parseCustomDate agora retorna a data formatada
                            return parseCustomDate(row.data);
                        },
                        Cell: ({ cell: { value }, row: { original } }) => (
                            <Link to={`/editar-conta/${original.id}`}>{value}</Link>
                        ),
                    },
                    {
                        Header: "Tipo",
                        accessor: row => row.tipo.charAt(0).toUpperCase() + row.tipo.slice(1) || '-',
                        Cell: ({ cell: { value }, row: { original } }) => (
                            <Link to={`/editar-conta/${original.id}`}>{value || '-'}</Link>
                        ),
                    },
                    {
                        Header: "Categoria",
                        accessor: row => {
                            if (row.tipo !== 'despesa' || !row.categoria_nome) return '-';
                            return row.subcategoria_nome ? `${row.categoria_nome} / ${row.subcategoria_nome}` : row.categoria_nome;
                        },
                        Cell: ({ cell: { value }, row: { original } }) => (
                            <Link to={`/editar-conta/${original.id}`}>{value || '-'}</Link>
                        ),
                    },
                    {
                        Header: "Excluir",
                        accessor: row => (
                            <div style={{ width: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Link 
                                    className="fs-4" 
                                    onClick={() => { 
                                        setModalData({ 
                                            id: row.id, 
                                            descricao: row.descricao,
                                            isParcelada: row.total_parcelas > 1,
                                            grupoId: row.grupo_parcelas
                                        }); 
                                        setShowModalConfirm(true); 
                                    }}
                                >
                                    <i className="bi bi-trash3-fill"></i>
                                </Link>
                            </div>
                        )
                    }
                ]
            }
        ],
        [contas, contasShow]
    );



    const isDateInRange = (date, startDate, endDate) =>
        (!startDate || date >= startDate) && (!endDate || date <= endDate);

    const isCompraMatch = (produto, compra) =>
        !compra || (compra === '1' && produto) || (compra === '0' && !produto);

    const isSameTipo = (tipoConta, tipo) =>
        !tipo || tipoConta == tipo;


    const filtrarLista = () => {
        const { inicio, fim, compra, tipo, valorMin, valorMax, categoria_despesa } = filterData;
        console.log(filterData);

        const selectedStartDate = inicio ? parseISO(inicio) : null;
        const selectedEndDate = fim ? parseISO(fim) : null;

        const filteredData = contas.filter(conta => {
            const osStartDate = parseISO(conta.data);

            const isDateInRangeCondition =
                isDateInRange(osStartDate, selectedStartDate, selectedEndDate);

            const isCompraMatchCondition = isCompraMatch(conta.produto, compra);

            const isSameTipoCondition = isSameTipo(conta.tipo, tipo);

            const isValueInRangeCondition =
                (!valorMin || Math.round(conta.valor) >= Math.round(valorMin)) &&
                (!valorMax || Math.round(conta.valor) <= Math.round(valorMax));
                
            const isCategoriaMatch = 
                !categoria_despesa || (conta.categoria_despesa == categoria_despesa);

            return (
                isDateInRangeCondition &&
                isCompraMatchCondition &&
                isSameTipoCondition &&
                isValueInRangeCondition &&
                isCategoriaMatch
            );
        });

        setContasShow(filteredData);
    };

// Módulo de relatórios - Funções para manipulação de relatórios financeiros
const RelatorioModule = {
    // Renderiza o cabeçalho do PDF
    renderizarCabecalho: (pdf, titulo, periodo, pageWidth, margin) => {
        // Título
        pdf.setFontSize(18);
        pdf.text(titulo, pageWidth/2, margin + 10, { align: 'center' });
        
        // Informações do período
        pdf.setFontSize(12);
        pdf.text(`Período: ${periodo}`, margin, margin + 25);
    },
    
    // Renderiza o resumo financeiro (receitas, despesas, saldo)
    renderizarResumoFinanceiro: (pdf, dados, margin) => {
        const formatValue = (value) => value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
        
        pdf.setFontSize(12);
        const receitas = formatValue(dados.receitas);
        const despesas = formatValue(dados.despesas);
        const saldo = formatValue(dados.saldo);
        
        pdf.text(`Receitas: ${receitas}`, margin, margin + 35);
        pdf.text(`Despesas: ${despesas}`, margin, margin + 45);
        pdf.text(`Saldo: ${saldo}`, margin, margin + 55);
    },
    
    // Desenha o cabeçalho da tabela de detalhes
    desenharCabecalhoTabela: (pdf, colunas, margin, y, contentWidth) => {
        pdf.setFillColor(220, 220, 220);
        pdf.rect(margin, y, contentWidth, 10, 'F');
        
        let x = margin;
        pdf.setFont('helvetica', 'bold');
        colunas.forEach(col => {
            pdf.text(col.titulo, x + 3, y + 6);
            x += col.largura;
        });
        pdf.setFont('helvetica', 'normal');
        
        return y + 10;
    },
    
    // Renderiza uma linha da tabela de detalhes
    renderizarLinhaTabela: (pdf, conta, colunas, margin, y, idx, contentWidth) => {
        // Alternar cores de linha
        if (idx % 2 === 0) {
            pdf.setFillColor(240, 240, 240);
            pdf.rect(margin, y, contentWidth, 10, 'F');
        }
        
        // Desenhar valores de cada coluna
        let x = margin;
        
        // Data
        pdf.text(parseCustomDate(conta.data), x + 3, y + 6);
        x += colunas[0].largura;
        
        // Descrição (truncada se necessário)
        const descricao = conta.descricao?.length > 25 ? 
            conta.descricao.substr(0, 25) + '...' : 
            conta.descricao || '-';
        pdf.text(descricao, x + 3, y + 6);
        x += colunas[1].largura;
        
        // Tipo
        pdf.text(conta.tipo.charAt(0).toUpperCase() + conta.tipo.slice(1), x + 3, y + 6);
        x += colunas[2].largura;
        
        // Valor (com cor correspondente)
        const valorFormatado = Number(conta.valor).toLocaleString('pt-BR', { 
            style: 'currency', currency: 'BRL' 
        });
        
        // Aplicar cor correspondente ao tipo de conta
        if (conta.tipo === 'receita') {
            pdf.setTextColor(40, 167, 69); // Verde para receitas
        } else {
            pdf.setTextColor(220, 53, 69); // Vermelho para despesas
        }
        
        pdf.text(valorFormatado, x + colunas[3].largura - 3, y + 6, { align: 'right' });
        pdf.setTextColor(0, 0, 0); // Resetar para preto
        
        return y + 10;
    },
    
    // Adiciona o rodapé ao PDF
    adicionarRodape: (pdf, pageWidth, pageHeight, margin) => {
        const dataGeracao = format(new Date(), 'dd/MM/yyyy HH:mm');
        pdf.setFontSize(8);
        pdf.text(`Relatório gerado em ${dataGeracao}`, pageWidth - margin, pageHeight - margin, { align: 'right' });
    },
    
    // Gera o nome do arquivo do relatório
    gerarNomeArquivo: () => {
        const dataAtual = format(new Date(), 'dd-MM-yyyy');
        return `fechamento-caixa-${dataAtual}.pdf`;
    }
};

// Função refatorada para exportar o relatório para PDF
const exportarPDF = () => {
    setLoadingRelatorio(true);
    
    try {
        // Configurações do PDF
        const pdf = new jsPDF('p', 'mm', 'a4');
        const margin = 15; // Margem em todos os lados
        const pageWidth = 210;
        const pageHeight = 297;
        const contentWidth = pageWidth - (margin * 2);
        
        // Renderizar o cabeçalho
        RelatorioModule.renderizarCabecalho(
            pdf, 
            'Relatório de Fechamento de Caixa', 
            relatorioGerado.periodo, 
            pageWidth, 
            margin
        );
        
        // Renderizar o resumo financeiro
        RelatorioModule.renderizarResumoFinanceiro(pdf, relatorioGerado, margin);
        
        // Adicionar o título da seção de detalhamento
        pdf.setFontSize(14);
        pdf.text('Detalhamento', margin, margin + 70);
        
        pdf.setFontSize(10);
        let y = margin + 80;
        
        // Definição das colunas
        const colunas = [
            { titulo: 'Data', largura: 25 },
            { titulo: 'Descrição', largura: 60 },
            { titulo: 'Tipo', largura: 25 },
            { titulo: 'Valor', largura: 30 }
        ];
        
        // Desenhar cabeçalho da tabela
        y = RelatorioModule.desenharCabecalhoTabela(pdf, colunas, margin, y, contentWidth);
        
        // Renderizar cada linha da tabela
        relatorioGerado.detalhes.forEach((conta, idx) => {
            // Verificar se precisa adicionar nova página
            if (y > pageHeight - margin) {
                pdf.addPage();
                y = margin;
                
                // Redesenhar cabeçalho na nova página
                y = RelatorioModule.desenharCabecalhoTabela(pdf, colunas, margin, y, contentWidth);
            }
            
            // Renderizar linha - passando contentWidth para garantir largura consistente
            y = RelatorioModule.renderizarLinhaTabela(pdf, conta, colunas, margin, y, idx, contentWidth);
        });
        
        // Adicionar rodapé
        RelatorioModule.adicionarRodape(pdf, pageWidth, pageHeight, margin);
        
        // Salvar o PDF
        pdf.save(RelatorioModule.gerarNomeArquivo());
    } catch (error) {
        console.error("Erro ao gerar PDF:", error);
        alert("Ocorreu um erro ao gerar o PDF. Por favor, tente novamente.");
    } finally {
        setLoadingRelatorio(false);
    }
};

    // Função para gerar o relatório de fechamento
    const gerarRelatorio = () => {
        try {
            setLoadingRelatorio(true);
            
            // Verificar se as datas são válidas antes de prosseguir
            if (!relatorioData.periodoInicio || !relatorioData.periodoFim) {
                console.error("Datas inválidas para o relatório");
                setLoadingRelatorio(false);
                return;
            }
            
            const dataInicio = parseISO(relatorioData.periodoInicio);
            const dataFim = parseISO(relatorioData.periodoFim);
            
            if (!isValid(dataInicio) || !isValid(dataFim)) {
                console.error("Datas inválidas após parseISO");
                setLoadingRelatorio(false);
                return;
            }
            
            // Filtrar contas pelo período selecionado
            let contasFiltradas = contas.filter(conta => {
                try {
                    const dataConta = parseISO(conta.data);
                    return isValid(dataConta) && dataConta >= dataInicio && dataConta <= dataFim;
                } catch (err) {
                    console.error("Erro ao analisar data da conta:", err);
                    return false;
                }
            });
            
            // Aplicar filtro adicional pelo modo selecionado (todos, despesas, receitas)
            if (relatorioData.modo === 'despesas') {
                contasFiltradas = contasFiltradas.filter(conta => conta.tipo === 'despesa');
            } else if (relatorioData.modo === 'receitas') {
                contasFiltradas = contasFiltradas.filter(conta => conta.tipo === 'receita');
            }
            
            // Processar os dados para o relatório
            let receitas = 0;
            let despesas = 0;
            
            contasFiltradas.forEach(conta => {
                if (conta.tipo === 'receita') {
                    receitas += Number(conta.valor);
                } else {
                    despesas += Number(conta.valor);
                }
            });
            
            const dadosRelatorio = {
                periodo: `${format(dataInicio, 'dd/MM/yyyy')} a ${format(dataFim, 'dd/MM/yyyy')}`,
                receitas,
                despesas,
                saldo: receitas - despesas,
                detalhes: contasFiltradas,
                modo: relatorioData.modo // Incluir o modo no relatório gerado
            };
            
            setRelatorioGerado(dadosRelatorio);
        } catch (error) {
            console.error("Erro ao gerar relatório:", error);
        } finally {
            setLoadingRelatorio(false);
        }
    };

    // Função para preparar e mostrar o relatório do mês atual
    const mostrarRelatorioMesAtual = () => {
        try {
            const today = new Date();
            if (!isValid(today)) {
                console.error("Data atual inválida");
                return;
            }
            
            const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
            if (!isValid(firstDayOfMonth)) {
                console.error("Primeiro dia do mês inválido");
                return;
            }
            
            const nextMonth = addMonths(firstDayOfMonth, 1);
            if (!isValid(nextMonth)) {
                console.error("Próximo mês inválido");
                return;
            }
            
            const lastDayOfMonth = subDays(nextMonth, 1);
            if (!isValid(lastDayOfMonth)) {
                console.error("Último dia do mês inválido");
                return;
            }
            
            const periodoInicio = format(firstDayOfMonth, 'yyyy-MM-dd');
            const periodoFim = format(lastDayOfMonth, 'yyyy-MM-dd');
            
            // Atualize o estado primeiro
            setRelatorioData({
                periodoInicio,
                periodoFim,
                modo: 'todos'
            });
            
            // Abrir o modal
            setShowRelatorioModal(true);
            
            // Usar setTimeout para garantir que o estado foi atualizado
            setTimeout(() => {
                // Chamar diretamente com os valores calculados para evitar dependência do estado
                try {
                    setLoadingRelatorio(true);
                    
                    // Filtrar contas pelo período selecionado
                    const contasFiltradas = contas.filter(conta => {
                        try {
                            const dataConta = parseISO(conta.data);
                            return isValid(dataConta) && 
                                   dataConta >= firstDayOfMonth && 
                                   dataConta <= lastDayOfMonth;
                        } catch (err) {
                            console.error("Erro ao analisar data da conta:", err);
                            return false;
                        }
                    });
                    
                    // Processar os dados para o relatório
                    let receitas = 0;
                    let despesas = 0;
                    
                    contasFiltradas.forEach(conta => {
                        if (conta.tipo === 'receita') {
                            receitas += Number(conta.valor);
                        } else {
                            despesas += Number(conta.valor);
                        }
                    });
                    
                    const dadosRelatorio = {
                        periodo: `${format(firstDayOfMonth, 'dd/MM/yyyy')} a ${format(lastDayOfMonth, 'dd/MM/yyyy')}`,
                        receitas,
                        despesas,
                        saldo: receitas - despesas,
                        detalhes: contasFiltradas,
                        modo: 'todos' // Valor padrão do modo
                    };
                    
                    setRelatorioGerado(dadosRelatorio);
                    setLoadingRelatorio(false);
                } catch (error) {
                    console.error("Erro ao gerar relatório inicial:", error);
                    setLoadingRelatorio(false);
                }
            }, 100);
        } catch (error) {
            console.error("Erro ao configurar relatório do mês atual:", error);
        }
    };

    // Modificação para o título do relatório com base no modo selecionado
    const getTituloRelatorio = (modo) => {
        switch(modo) {
            case 'despesas':
                return 'Relatório de Despesas';
            case 'receitas':
                return 'Relatório de Receitas';
            default:
                return 'Relatório de Fechamento de Caixa';
        }
    };

    return (
        <>
            <div className="container-fluid p-0">
                <div className="d-flex justify-content-between">
                    <div>
                        <h1 className="h3 mb-3 font-bold">Listagem de Contas</h1>
                    </div>
                    <div>
                        <Link to="/cadastrar-conta" className='btn btn-primary fs-5 me-2'><FontAwesomeIcon icon="fa-solid fa-plus" /> | Nova</Link>
                        <button className="btn btn-primary me-2" onClick={() => { setShowFilterModal(true) }}>Filtrar</button>
                        <button className="btn btn-success" onClick={mostrarRelatorioMesAtual}>
                            <FontAwesomeIcon icon="fa-solid fa-file-invoice-dollar" className="me-1" /> Relatório de Fechamento
                        </button>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                {status.message && (
                                    <div className={`alert ${status.success ? 'alert-success' : 'alert-danger'} alert-dismissible`} role="alert">
                                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                        <div className="alert-icon">
                                            <i className="far fa-fw fa-bell"></i>
                                        </div>
                                        <div className="alert-message">
                                            {status.message}
                                        </div>
                                    </div>
                                )}
                                <Table columns={columns} data={contasShow} />
                                <div className="d-flex justify-content-end">
                                    <div className="d-flex align-items-center">
                                        <span className="me-2">Receitas:</span>
                                        <span className="fs-4 fw-bold text-success">{totalShow.receitas}</span>
                                    </div>
                                    <div className="d-flex align-items-center ms-4">
                                        <span className="me-2">Despesas:</span>
                                        <span className="fs-4 fw-bold text-danger">{totalShow.despesas}</span>
                                    </div>
                                    <div className="d-flex align-items-center ms-4">
                                        <span className="me-2">Saldo:</span>
                                        <span className={`fs-4 fw-bold ${parseFloat(totalShow.total?.replace(/[^\d,-]/g, '').replace(',', '.')) >= 0 ? 'text-success' : 'text-danger'}`}>
                                            {totalShow.total}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal centered show={showModalConfirm} onHide={() => setShowModalConfirm(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirmação</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Tem certeza que deseja deletar o registro <b>{modalData.descricao || modalData.id}</b>?</p>
                    
                    {modalData.isParcelada && (
                        <div className="form-check mt-3">
                            <input 
                                type="checkbox" 
                                className="form-check-input" 
                                id="excluirTodasParcelas"
                                checked={excluirTodasParcelas}
                                onChange={(e) => setExcluirTodasParcelas(e.target.checked)}
                            />
                            <label className="form-check-label" htmlFor="excluirTodasParcelas">
                                Excluir todas as parcelas relacionadas?
                            </label>
                            <div className="text-muted small">
                                Se não marcar esta opção, apenas este registro será excluído.
                            </div>
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <button 
                        className="btn btn-primary" 
                        onClick={() => { deleteRecord(modalData.id, excluirTodasParcelas) }}
                    >
                        Confirmar
                    </button>
                    <button className="btn btn-secondary" onClick={() => setShowModalConfirm(false)}>
                        Cancelar
                    </button>
                </Modal.Footer>
            </Modal>

            <Modal centered show={showFilterModal} onHide={() => setShowFilterModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Filtro</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label className="form-label">Data Inicial</label>
                                <input
                                    type="date"
                                    className="form-control"
                                    name="dataInicial"
                                    value={filterData.inicio || ''}
                                    onChange={e => {
                                        setFilterData({
                                            ...filterData,
                                            inicio: e.target.value
                                        })
                                    }}
                                />
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="form-group">
                                <label className="form-label">Data Final</label>
                                <input
                                    type="date"
                                    className="form-control"
                                    name="dataInicial"
                                    value={filterData.fim || ''}
                                    onChange={e => {
                                        setFilterData({
                                            ...filterData,
                                            fim: e.target.value
                                        })
                                    }}
                                />
                            </div>
                        </div>

                        <div className='col-md-6'>
                            <div className="form-group">
                                <label className="form-label">Tipo</label>
                                <select
                                    className="form-control"
                                    name="status"
                                    value={filterData.tipo}
                                    onChange={e => {
                                        setFilterData({
                                            ...filterData,
                                            tipo: e.target.value
                                        })
                                    }}
                                >
                                    <option value="">Selecione</option>
                                    <option value="receita">Receita</option>
                                    <option value="despesa">Despesa</option>
                                </select>

                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="form-group">
                                <label className="form-label">Categoria</label>
                                <select
                                    className="form-control"
                                    name="status"
                                    value={filterData.conta}
                                    onChange={e => {
                                        setFilterData({
                                            ...filterData,
                                            compra: e.target.value
                                        })
                                    }}
                                >
                                    <option value="">Selecione</option>
                                    <option value="1">Compra de produto</option>
                                    <option value="0">Demais contas</option>
                                </select>

                            </div>
                        </div>


                        <div className='col-md-6'>
                            <div className="form-group">
                                <label className="form-label">Valor min</label>
                                <CurrencyInput //default value tem que usar o modalPagamentoData.id para encontrar o campo valor_total e subtrair o campo desconto

                                    onChange={(event, value) => {
                                        setFilterData({
                                            ...filterData,
                                            valorMin: event.replace('R$ ', '').replace('.', '').replace(',', '.')
                                        })
                                    }}
                                    defaultValue={filterData.valorMin || '0'}
                                />
                            </div>
                        </div>

                        <div className='col-md-6'>

                            <div className="form-group">
                                <label className="form-label">Valor max</label>
                                <CurrencyInput //default value tem que usar o modalPagamentoData.id para encontrar o campo valor_total e subtrair o campo desconto

                                    onChange={(event, value) => {
                                        setFilterData({
                                            ...filterData,
                                            valorMax: event.replace('R$ ', '').replace('.', '').replace(',', '.')
                                        })
                                    }}
                                    defaultValue={filterData.valorMax || '0'}
                                />
                            </div>
                        </div>

                        <div className='col-md-6'>
                            <div className="form-group">
                                <label className="form-label">Categoria de Despesa</label>
                                <select
                                    className="form-control"
                                    name="categoria_despesa"
                                    value={filterData.categoria_despesa || ''}
                                    onChange={e => {
                                        setFilterData({
                                            ...filterData,
                                            categoria_despesa: e.target.value
                                        })
                                    }}
                                >
                                    <option value="">Todas as categorias</option>
                                    {categoriasDespesa.map(categoria => (
                                        <option key={categoria.id} value={categoria.id}>
                                            {categoria.nome}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>

                    </div>


                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-primary" onClick={() => { filtrarLista() }}>Confirmar</button>
                    {/* <button className="btn btn-secondary" onClick={() => setShowFilterModal(false)}>Cancelar</button> */}
                    <button className="btn btn-danger" onClick={() => { setFilterData([]); setContasShow(contas); setShowFilterModal(false) }}>Limpar</button>
                </Modal.Footer>
            </Modal >

            {/* Modal de Relatório de Fechamento */}
            <Modal 
                centered 
                show={showRelatorioModal} 
                onHide={() => setShowRelatorioModal(false)}
                size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {relatorioGerado ? getTituloRelatorio(relatorioGerado.modo) : 'Relatório de Fechamento de Caixa'}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row mb-3">
                        <div className="col-md-4">
                            <div className="form-group">
                                <label className="form-label">Data Inicial</label>
                                <input
                                    type="date"
                                    className="form-control"
                                    name="dataInicial"
                                    value={relatorioData.periodoInicio}
                                    onChange={e => {
                                        setRelatorioData({
                                            ...relatorioData,
                                            periodoInicio: e.target.value
                                        })
                                    }}
                                />
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className="form-group">
                                <label className="form-label">Data Final</label>
                                <input
                                    type="date"
                                    className="form-control"
                                    name="dataFinal"
                                    value={relatorioData.periodoFim}
                                    onChange={e => {
                                        setRelatorioData({
                                            ...relatorioData,
                                            periodoFim: e.target.value
                                        })
                                    }}
                                />
                            </div>
                        </div>
                        
                        <div className="col-md-4">
                            <div className="form-group">
                                <label className="form-label">Tipo de Relatório</label>
                                <select
                                    className="form-select"
                                    value={relatorioData.modo}
                                    onChange={e => {
                                        setRelatorioData({
                                            ...relatorioData,
                                            modo: e.target.value
                                        })
                                    }}
                                >
                                    <option value="todos">Receitas e Despesas</option>
                                    <option value="despesas">Apenas Despesas</option>
                                    <option value="receitas">Apenas Receitas</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    
                    <div className="d-flex justify-content-center mb-3">
                        <button 
                            className="btn btn-primary" 
                            onClick={gerarRelatorio}
                            disabled={loadingRelatorio}
                        >
                            {loadingRelatorio ? 'Gerando...' : 'Atualizar Relatório'}
                        </button>
                    </div>
                    
                    {relatorioGerado && (
                        <div id="relatorio-fechamento" className="mt-4">
                            <h4 className="text-center mb-3">{getTituloRelatorio(relatorioGerado.modo)}</h4>
                            
                            <div className="alert alert-info">
                                <strong>Período:</strong> {relatorioGerado.periodo}
                            </div>
                            
                            <div className="row mb-4">
                                {(relatorioGerado.modo === 'todos' || relatorioGerado.modo === 'receitas') && (
                                    <div className="col-md-4">
                                        <div className="card">
                                            <div className="card-body text-center">
                                                <h5>Total Receitas</h5>
                                                <h3 className="text-success">
                                                    {relatorioGerado.receitas.toLocaleString('pt-BR', { 
                                                        style: 'currency', 
                                                        currency: 'BRL' 
                                                    })}
                                                </h3>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                
                                {(relatorioGerado.modo === 'todos' || relatorioGerado.modo === 'despesas') && (
                                    <div className="col-md-4">
                                        <div className="card">
                                            <div className="card-body text-center">
                                                <h5>Total Despesas</h5>
                                                <h3 className="text-danger">
                                                    {relatorioGerado.despesas.toLocaleString('pt-BR', { 
                                                        style: 'currency', 
                                                        currency: 'BRL' 
                                                    })}
                                                </h3>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                
                                {relatorioGerado.modo === 'todos' && (
                                    <div className="col-md-4">
                                        <div className="card">
                                            <div className="card-body text-center">
                                                <h5>Saldo Final</h5>
                                                <h3 className={relatorioGerado.saldo >= 0 ? "text-success" : "text-danger"}>
                                                    {relatorioGerado.saldo.toLocaleString('pt-BR', { 
                                                        style: 'currency', 
                                                        currency: 'BRL' 
                                                    })}
                                                </h3>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                            
                            <h5>Detalhamento</h5>
                            {/* Adicionando área scrollável para a tabela */}
                            <div className="table-responsive" style={{ maxHeight: '300px', overflowY: 'auto', marginBottom: '15px' }}>
                                <table className="table table-striped table-bordered">
                                    <thead style={{ position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 10 }}>
                                        <tr>
                                            <th>Data</th>
                                            <th>Descrição</th>
                                            <th>Tipo</th>
                                            <th>Categoria</th>
                                            <th>Valor</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {relatorioGerado.detalhes.map((conta, index) => (
                                            <tr key={index}>
                                                <td>{parseCustomDate(conta.data)}</td>
                                                <td>{conta.descricao}</td>
                                                <td>{conta.tipo.charAt(0).toUpperCase() + conta.tipo.slice(1)}</td>
                                                <td>
                                                    {conta.tipo === 'despesa' && conta.categoria_nome ? 
                                                        (conta.subcategoria_nome ? 
                                                            `${conta.categoria_nome} / ${conta.subcategoria_nome}` : 
                                                            conta.categoria_nome) : 
                                                        '-'}
                                                </td>
                                                <td className={conta.tipo === 'receita' ? 'text-success' : 'text-danger'}>
                                                    {Number(conta.valor).toLocaleString('pt-BR', { 
                                                        style: 'currency', 
                                                        currency: 'BRL' 
                                                    })}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    {relatorioGerado && (
                        <button 
                            className="btn btn-primary" 
                            onClick={exportarPDF}
                            disabled={loadingRelatorio}
                        >
                            <FontAwesomeIcon icon="fa-solid fa-file-pdf" className="me-1" /> 
                            Exportar PDF
                        </button>
                    )}
                    <button className="btn btn-secondary" onClick={() => setShowRelatorioModal(false)}>
                        Fechar
                    </button>
                </Modal.Footer>
            </Modal>
        </>


    );

}

export default ListarContas