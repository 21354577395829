import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useParams, useNavigate } from 'react-router-dom';
import Api from '../../Api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function EditarCategoriaDespesas() {
    const { id } = useParams();
    const navigate = useNavigate();
    const { register, handleSubmit, setValue, formState: { errors } } = useForm();
    const [status, setStatus] = useState({ success: false, message: '' });
    const [categorias, setCategorias] = useState([]);
    const [isSubcategoria, setIsSubcategoria] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const buscarCategoria = async () => {
            setLoading(true);
            try {
                // Buscar dados da categoria
                const catResponse = await Api.get(`BuscarCategoriaDespesa?id=${id}`);
                
                if (catResponse.data && catResponse.data.success && catResponse.data.data.length > 0) {
                    const categoriaData = catResponse.data.data[0];
                    
                    // Verificar se é uma subcategoria baseado no campo isSubcategoria da resposta
                    const ehSubcategoria = categoriaData.isSubcategoria === true;
                    setIsSubcategoria(ehSubcategoria);
                    
                    setValue('nome', categoriaData.nome);
                    
                    if (ehSubcategoria && categoriaData.categoriaPai) {
                        setValue('categoriaPai', categoriaData.categoriaPai);
                        
                        // Se for uma subcategoria, redirecionar para a tela correta
                        navigate(`/editar-subcategoria-despesa/${id}`, { replace: true });
                        return;
                    }
                } else {
                    setStatus({
                        success: false,
                        message: "Categoria não encontrada."
                    });
                }
                
                // Buscar todas as categorias para popular o dropdown
                const allCatResponse = await Api.get('ListarCategoriasDespesa');
                if (allCatResponse.data && allCatResponse.data.categorias) {
                    // Filtrar para não mostrar a categoria atual como opção de pai (evitar loop)
                    const categoriasDisponiveis = allCatResponse.data.categorias.filter(cat => cat.id !== parseInt(id));
                    setCategorias(categoriasDisponiveis);
                }
            } catch (error) {
                console.log(error);
                setStatus({
                    success: false,
                    message: "Erro ao buscar dados da categoria."
                });
            } finally {
                setLoading(false);
            }
        };

        buscarCategoria();
    }, [id, setValue, navigate]);

    const onSubmit = async (data) => {
        window.scrollTo(0, 0);
        try {
            data.id = id;
            data.isSubcategoria = isSubcategoria;
            
            const response = await Api.put('EditarCategoriaDespesa', JSON.stringify(data), {
                headers: { 'Content-Type': 'application/json' }
            });
            
            setStatus(response.data);

            if (response.data.success) {
                // Redirecionar após 2 segundos
                setTimeout(() => {
                    navigate('/listar-categorias-despesas');
                }, 2000);
            }
        } catch (error) {
            console.log(error);
            if (error.response && error.response.data && error.response.data.error) {
                setStatus({
                    success: false,
                    message: error.response.data.error,
                });
            } else {
                setStatus({
                    success: false,
                    message: "Ocorreu um erro ao editar a categoria. Tente novamente mais tarde."
                });
            }
        }
    };

    if (loading) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
                <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Carregando...</span>
                </div>
            </div>
        );
    }

    return (
        <div className='page-full'>
            <form className="bg-white" onSubmit={handleSubmit(onSubmit)}>
                <div className="header-page-full mb-4 cursor-pointer" onClick={() => window.history.back()} >
                    <FontAwesomeIcon icon="fa-solid fa-arrow-left" />
                </div>
                <div className="pt-5 m-5">
                    <div className="row d-flex justify-content-center align-items-top">
                        <div className='col-md-2'>
                            <span className="ant-avatar-circle nex-avatar default circle-icon">
                                <FontAwesomeIcon icon="fa-solid fa-table-cells-large" />
                            </span>
                        </div>
                        <div className="col-md-8 mb-5">
                            <h1 className="h3 mb-3 fw-bold">Editar Categoria de Despesa</h1>

                            {status.message && (
                                <div className={`alert ${status.success ? 'alert-success' : 'alert-danger'} alert-dismissible`} role="alert">
                                    <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                    <div className="alert-icon">
                                        <i className="far fa-fw fa-bell"></i>
                                    </div>
                                    <div className="alert-message">
                                        {status.message}
                                    </div>
                                </div>
                            )}

                            <div className="mb-3">
                                <label className="form-label">Nome da Categoria</label>
                                <input type="text" className="form-control" {...register("nome", { required: true })} />
                                {errors.nome && <span className="text-danger">Este campo é obrigatório</span>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-page-full">
                    <div className="footer-client">
                        <button className="btn btn-light button-large fw-bold"> ATUALIZAR </button>
                        <button type="button" className="btn text-white button-large fw-bold" onClick={() => window.history.back()}> CANCELAR </button>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default EditarCategoriaDespesas;
