import React, { useEffect, useState } from 'react'
import Table from '../../Components/Table'
import Api from '../../Api'
import { Link, useNavigate } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function ListarCategoriasDespesas() {
    const navigate = useNavigate();
    const [showModalConfirm, setShowModalConfirm] = useState(false);
    const [modalData, setModalData] = useState({});
    const [status, setStatus] = useState({ success: false, message: '' })
    const [expandedCategories, setExpandedCategories] = useState({});

    const toggleSubcategory = (categoryId) => {
        setExpandedCategories(prev => ({
            ...prev,
            [categoryId]: !prev[categoryId]
        }));
    };

    const deleteRecord = async (data) => {
        try {
            const response = await Api.delete(`DeletarCategoriaDespesa/${data}`);
            setShowModalConfirm(false)
            
            if (response.data.success === true) {
                setStatus(response.data)
            }
        } catch (error) {
            console.log(error)
            // Mostra uma mensagem de erro genérica ao usuário
            setStatus({
                success: false,
                message: "Ocorreu um erro ao apagar a categoria. Tente novamente mais tarde."
            })
        }
    };

    // Função para editar subcategoria com navegação programática
    const editarSubcategoria = (subId) => {
        // Aqui nos certificamos de que estamos direcionando para a tela de edição de subcategoria
        navigate(`/editar-subcategoria-despesa/${subId}`);
    };

    const columns = React.useMemo(
        () => [
            {
                Header: '',
                isVisible: false,
                hideHeader: false,
                id: 'id',
                columns: [
                    {
                        Header: "#",
                        accessor: row => row.id ?? '-'
                    },
                    {
                        Header: "Nome",
                        accessor: row => row.nome || '-',
                        Cell: ({ cell: { value }, row: { original } }) => (
                            <div>
                                <Link to={`/editar-categoria-despesa/${original.id}`}>{value || '-'}</Link>
                                {original.subcategorias && original.subcategorias.length > 0 && (
                                    <span 
                                        className="ms-2" 
                                        onClick={(e) => {
                                            e.preventDefault();
                                            toggleSubcategory(original.id);
                                        }}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        <i className={`bi bi-chevron-${expandedCategories[original.id] ? 'up' : 'down'}`}></i>
                                    </span>
                                )}
                                
                                {expandedCategories[original.id] && original.subcategorias && (
                                    <ul className="list-group mt-2">
                                        {original.subcategorias.map(sub => (
                                            <li key={sub.id} className="list-group-item d-flex justify-content-between align-items-center">
                                                {sub.nome}
                                                <div>
                                                    <button 
                                                        className="btn btn-link p-0 me-2"
                                                        onClick={() => editarSubcategoria(sub.id)}
                                                    >
                                                        <i className="bi bi-pencil"></i>
                                                    </button>
                                                    <button 
                                                        className="btn btn-link p-0"
                                                        onClick={() => { 
                                                            setModalData({ nome: sub.nome, id: sub.id, isSubcategory: true, parentId: original.id }); 
                                                            setShowModalConfirm(true); 
                                                        }}
                                                    >
                                                        <i className="bi bi-trash3-fill"></i>
                                                    </button>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </div>
                        ),
                    },
                    {
                        Header: "Excluir",
                        accessor: row =>
                            <div style={{ width: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Link className="fs-4" onClick={() => { 
                                    setModalData({ nome: row.nome, id: row.id }); 
                                    setShowModalConfirm(true); 
                                }}>
                                    <i className="bi bi-trash3-fill"></i>
                                </Link>
                            </div>
                    }
                ]
            }
        ],
        [expandedCategories]
    );

    const [Categorias, setCategorias] = useState([]);

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await Api.get('ListarCategoriasDespesa');
                if (response.data && response.data.categorias) {
                    setCategorias(response.data.categorias);
                }
            } catch (error) {
                console.error(error);
                setStatus({
                    success: false,
                    message: "Erro ao carregar categorias de despesa."
                });
            }
        }

        fetchData();
    }, [status]);

    return (
        <>
            <div className="container-fluid p-0">
                <div className="d-flex justify-content-between">
                    <div>
                        <h1 className="h3 mb-3 font-bold">Listagem de Categorias de Despesa</h1>
                    </div>
                    <div>
                        <Link to="/cadastrar-categoria-despesa" className='btn btn-primary fs-5'>
                            <FontAwesomeIcon icon="fa-solid fa-plus" /> | Nova Categoria
                        </Link>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                {status.message && (
                                    <div className={`alert ${status.success ? 'alert-success' : 'alert-danger'} alert-dismissible`} role="alert">
                                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                        <div className="alert-icon">
                                            <i className="far fa-fw fa-bell"></i>
                                        </div>
                                        <div className="alert-message">
                                            {status.message}
                                        </div>
                                    </div>
                                )}
                                <Table columns={columns} data={Categorias} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal centered show={showModalConfirm} onHide={() => setShowModalConfirm(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirmação</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Tem certeza que deseja deletar {modalData.isSubcategory ? 'a subcategoria' : 'a categoria'}: <b>{modalData.nome}</b>?
                    {modalData.isSubcategory ? '' : 
                        <div className="alert alert-warning mt-2">
                            <strong>Atenção!</strong> Todas as subcategorias associadas também serão excluídas.
                        </div>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-primary" onClick={() => { deleteRecord(modalData.id) }}>Confirmar</button>
                    <button className="btn btn-secondary" onClick={() => setShowModalConfirm(false)}>Cancelar</button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ListarCategoriasDespesas